<template>
  <div>
    <hero-carousel v-if="banners.length" :banners="banners" :autoplay="autoplay"/>

    <!-- featured products -->
    <section class="cf overflow-hidden">
      <div :class="[featuredProductsColor, { 'cursor-pointer': hasIntroLink }]" :is="hasIntroLink ? 'router-link' : 'div'" :to="localeLink(null, fields.intro_link)" class="mb2 mb5-l pb2 block">
        <prismic-rich-text :field="fields.featured_products_intro" class="f1 f1-l serif ttu tc tracked mv3 mt5-l pt4 ph3"/>
        <div class="f18 mb4 ph4 ph5-l mh3-l lh-copy prismic tc">
          <prismic-rich-text :field="fields.featured_products_description"/>
        </div>
      </div>
      <div v-if="showFeaturedProducts" class="px-6 md:px-8 lg:px-12 relative">
        <div @lazybeforeunveil.once="loadProducts" ref="ps" data-bg="/1x1.png" class="lazyload slow">
          <div v-if="featuredProducts.length" :class="{ 'justify-center': !slidesOver }" class="mb5-l nowrap keen-slider" style="overflow: visible;">
            <div v-for="(product, i) in featuredProducts" :key="i" class="mb5 keen-slider__slide prod-slider-slide px-4">
              <product-grid-item :product="product" :no-price="false" :btn="true"/>
            </div>
          </div>

          <div v-if="slidesOver" class="text-white">
            <button @click="prev" class="opacity-50 hover:opacity-100 m-4 rounded-full w-8 h-8 grid place-items-center absolute top-slides-btn left-0">
              <svg width="7" height="20" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 21L1 10.5L8 0.5" stroke="black" stroke-width="3"/></svg>
            </button>

            <button @click="next" class="opacity-50 hover:opacity-100 m-4 w-8 h-8 grid place-items-center absolute top-slides-btn right-0">
              <svg width="7" height="20" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="translate-x-px"><path d="M1 1L8 11.5L1 21.5" stroke="black" stroke-width="3"/></svg>
            </button>
          </div>
        </div>
        <div v-if="!featuredProducts.length" class="mb5">
          <p class="f4 tc ttu serif tracked gray">{{ staticText('general', 'loading') }}</p>
        </div>
      </div>
    </section>

    <!-- featured content -->
    <section :class="featuredContentColor" class="banner-height bg-light-gray flex items-center justify-center tc relative pv6">
      <router-link :to="featuredLink" class="absolute top-0 right-0 bottom-0 left-0 flex-l">
        <template v-for="(item, i) in featuredContentBlocks">
          <!-- mobile -->
          <div v-if="isMobVid(item) && canPlayVideo" :key="i + 'a'" :class="featuredContentBlocks.length > 1 ? 'h-50 h-100-l' : 'h-100'" class="dn-m dn-l relative overflow-hidden w-100">
            <video playsinline autoplay muted loop class="cover bg-center bg-video">
              <source v-if="item.mobile_webm" :src="item.mobile_webm.url" type="video/webm"/>
              <source v-if="item.mobile_mp4" :src="item.mobile_mp4.url" type="video/mp4"/>
            </video>
          </div>
          <app-img v-else :src="item.mobile_image" :key="i + 'b'" :class="featuredContentBlocks.length > 1 ? 'h-50 h-100-l' : 'h-100'" class="dn-m dn-l cover bg-center w-100"/>
          <!-- desktop -->
          <div v-if="isVid(item) && canPlayVideo" :key="i + 'c'" :class="featuredContentBlocks.length > 1 ? 'h-50 h-100-l' : 'h-100'" class="dn db-m db-l relative overflow-hidden w-100">
            <video playsinline autoplay muted loop class="cover bg-center bg-video">
              <source v-if="item.video_webm" :src="item.video_webm.url" type="video/webm"/>
              <source v-if="item.video_mp4" :src="item.video_mp4.url" type="video/mp4"/>
            </video>
          </div>
          <app-img v-else :src="item.image" :key="i + 'd'" :class="featuredContentBlocks.length > 1 ? 'h-50 h-100-l' : 'h-100'" class="dn db-m db-l cover bg-center w-100"/>
        </template>
      </router-link>

      <div class="z-1 pointer ph3 block">
        <prismic-rich-text :field="fields.featured_content_main" class="f1 f1-l ma0 serif tracked ttu"/>
        <prismic-rich-text :field="fields.featured_content_description" class="measure-wide center f18 mt3"/>
        <p v-text="fields.featured_content_subtitle" class="f7 tracked mt4 mb0 ttu"></p>
      </div>
    </section>

    <!-- signup || other form -->
    <section v-if="otherFormVisible" ref="other" class="bg-blue white tc pv5 ph3 relative">
      <div class="pv1 ph3 ph0-l">
        <template v-if="!sampleFormComplete">
          <p v-text="fields.sample_title" class="f4 serif tracked ttu mb2"></p>
          <p v-text="fields.sample_subtitle" class="f17 mb4"></p>
          <prismic-rich-text :field="fields.sample_description" class="f4 serif tracked ttu measure center"/>
          <div class="mt4 mb0">
            <a @click="toggleSampleForm" v-text="fields.sample_action" class="dib btn ph4 pv3 f7 tracked ttu pointer"></a>
          </div>
        </template>
        <template v-else>
          <p v-text="fields.sample_form_thanks" class="f4 serif tracked ttu mb2 measure center mb3"></p>
          <p v-text="fields.sample_form_thanks_sub" class="f17 mb4"></p>
        </template>
      </div>
    </section>

    <section v-else @click="showSignup" class="hidden bg-blue white tc pv5 ph3 relative pointer">
      <div class="pv1 ph3 ph0-l">
        <p v-text="fields.newsletter_title" class="f30 serif tracked ttu mb4"></p>
        <prismic-rich-text :field="fields.newsletter_message" class="f20"/>
        <div class="ttu white mt4">
          <div class="flex justify-center mw7 ph2-ns ph5-l center">
            <a class="f15 tracked mt3 mb0 ttu pointer">{{ staticText('general', 'subscribe') }}</a>
          </div>
        </div>
      </div>
      <div v-if="hasSubscribed" class="absolute top-0 right-0 bottom-0 left-0 bg-blue flex justify-center items-center">
        <p class="f30 serif tracked ttu">{{ staticText('general', 'subscribeThanks') }}</p>
      </div>
    </section>

    <!-- publications -->
    <section v-if="publications.length" class="pv2 ph3 center cf">
      <div class="w-100 mv4 pt3 pt0-l mv5-l">
        <prismic-rich-text :field="fields.reviews_title" class="ttu tc mt0 mb2 serif tracked f18"></prismic-rich-text>
      </div>
      <div class="flex items-center overflow-auto snap -mx-4">
        <div class="flex-auto"></div>
        <div v-for="(pub, i) in publications" :key="i" class="md:px-8 md:pb-8 flex-none">
          <anchor-switch :url="pub.pub_link" class="p-3 hover:opacity-60 transition-opacity block">
            <app-img :src="pub.pub_logo" :bg="false" class="max-w-press-img"/>
          </anchor-switch>
        </div>
        <div class="flex-auto"></div>
      </div>
    </section>

    <!-- journal -->
    <section class="pv2 mw8 ph3 center cf">
      <div @lazybeforeunveil.once="loadJournal" class="lazyload ph3 ph0-l">
        <div class="pv2 center cf">
          <div class="mt4 pt3 pt0-l mt5-l">
            <div v-if="first" class="mb4 mb0-ns">
              <p class="ttu tc mt0 mb2 serif tracked f6 f18-ns">{{ staticText('general', 'journal') }}</p>
              <journal-grid-item :fields="first" :featured="true" />
            </div>
            <div data-bg="/1x1.png" class="lazyload slow dn db-ns overflow-hidden" data-expand="-280">
              <div class="w-100 mt5 mb4 pb3">
                <p class="f3 serif tracked ttu tc">{{ staticText('general', 'moreJournal') }}</p>
              </div>
              <div v-if="posts.length" class="flex flex-wrap justify-center justify-start-l na4-l pv4-l">
                <template v-for="post in posts">
                  <journal-grid-item :fields="post" :key="post.id" :landscape="true"/>
                </template>
              </div>
            </div>
          </div>
          <div v-if="!posts.length" class="mb5">
            <p class="f4 tc ttu serif tracked gray">{{ staticText('general', 'loading') }}</p>
          </div>
        </div>
      </div>
    </section>

    <!-- reviews -->
    <section v-if="userReviews.length" class="mb-8 md:mb-24 mw8 ph3 center cf">
      <div class="w-100 mv4 pt3 pt0-l mv5-l">
        <prismic-rich-text :field="fields.user_reviews_title" class="ttu tc mt0 mb2 serif tracked f18"></prismic-rich-text>
      </div>
      <div class="flex flex-wrap items-start justify-center">
        <div v-for="(rev, i) in userReviews" :key="i" class="px-8 pb-8 max-w-xs">
          <anchor-switch :url="rev.review_link" class="p-3 hover:opacity-60 transition-opacity block">
            <div class="Stars f18 mb-3" :style="`--rating: ${rev.rating};`" :aria-label="`${rev.rating} out of 5 stars`"></div>
            <prismic-rich-text :field="rev.review_text" class="prismic mb-1"/>
            <p><strong>{{ rev.reviewer }}</strong></p>
          </anchor-switch>
        </div>
      </div>
    </section>

    <!-- promo -->
    <section v-if="fields.promo_title" class="bg-light-red red tc pv5 ph3 relative">
      <router-link :to="promoLink" class="pv1 ph3 ph0-l pointer">
        <p v-text="fields.promo_title" class="f18 serif tracked ttu mb4"></p>
        <prismic-rich-text :field="fields.promo_details" class="f17"/>
        <div class="ttu red mt3">
          <div class="flex justify-center mw7 ph2-ns ph5-l center">
            <p v-text="fields.promo_subtitle" class="f7 tracked mt3 mb0 ttu"></p>
          </div>
        </div>
      </router-link>
    </section>

    <!-- instagram -->
    <section v-if="!instaErr" class="pv2 ph3-l mw8 center">
      <div class="w-100 mv4 pt3 pt0-l mv5-l">
        <p v-html="staticText('general', 'stayInTouch')" class="f18 f30-l serif tracked ttu tc"></p>
      </div>
      <div @lazybeforeunveil.once="loadInsta" class="lazyload mb3 overflow-hidden">
        <p v-if="!instaPosts.length" class="f4 tc ttu serif tracked gray pb4">{{ instaMsg }}</p>
        <div v-else ref="is" class="flex flex-wrap justify-center justify-start-l na4-l pt2 pb6-l ws-normal-l overflow-auto ph1 ph0-l">
          <template v-for="(post, i) in instaPosts">
            <insta-post :post="post" :key="i" class="hiding"/>
          </template>
        </div>
      </div>
    </section>

    <!-- PromoTerms 
    <section class="bg-light-red red tc pv5 ph3 relative">
        <a href="https://prismic-io.s3.amazonaws.com/imersa/95951f0f-98f9-41f5-be37-c29cb8901e0b_Regolamento+Estate+2023.pdf" target="_blank">
        <p class="f18 serif ttu mb4">*Operazione estate Imersa</p>
          Regolamento completo della promozione
        <div class="ttu red mt3">
          <div class="flex justify-center mw7 ph2-ns ph5-l center">
            <p class="f7 mt3 mb0 ttu">Clicca qui</p>
          </div>
        </div>
       </a>
    </section>

    <section v-if="fields.promoterms_title" class="" style="text-align:center;">
      .
    </section>-->
    <transition name="fade">
      <use-form v-if="showSampleForm" :fields="fields" @close="closeSampleForm" @complete="completeSampleForm"/>
    </transition>

    <!-- campaign -->
    <campaign/>
  </div>
</template>

<script>
  import store from '@/store'
  import { mapGetters } from 'vuex'
  import ProductGridItem from '@/components/ProductGridItem'
  import JournalGridItem from '@/components/JournalGridItem'
  import InstaPost from '@/components/InstaPost'
  import Campaign from '@/views/Campaign'
  import OrderSample from '@/components/OrderSample'
  import AnchorSwitch from '@/components/AnchorSwitch.js'
  import HeroCarousel from '../components/HeroCarousel.vue'
  import { currentUrl } from '@/util'
  import KeenSlider from 'keen-slider'

  const pageName = 'homepage'
  const mainTitle = 'A new line of gentle yet effective products for a sensory-rich skincare experience'

  const UseForm = {
    functional: true,
    render (h, { data }) {
      const { fields } = data.attrs
      const el = fields.modal
      return h(el, data)
    }
  }

  export default {
    name: 'Home',
    metaInfo () {
      return {
        meta: [
          {
            name: 'description',
            content: this.seoDescription
          },
          {
            property: 'og:title',
            content: this.seoTitle,
            vmid: 'og:title'
          },
          {
            property: 'og:url',
            content: currentUrl(),
            vmid: 'og:url'
          },
          {
            property: 'og:image',
            content: (this.seoImage && this.seoImage.url) || require('../assets/Imersa-products.jpg'),
            vmid: 'og:image'
          }
        ]
      }
    },
    data () {
      return {
        canPlayVideo: false,
        showSampleForm: false,
        prodSlider: null,
        idx: 0
      }
    },
    computed: {
      page () {
        return this.$store.state.singles[pageName] || {}
      },
      loaded () {
        return this.page.id
      },
      fields () {
        return this.page.data || {}
      },
      hasIntroLink () {
        return this.fields.intro_link && this.fields.intro_link.link_type !== 'Any'
      },
      bannerText () {
        if (!this.fields.banner_text) return
        return this.fields.banner_text.replace('No', 'N<sup>O</sup>')
      },
      banners () {
        if (!this.fields.body1) return []
        return this.fields.body1
      },
      autoplay () {
        if (!this.fields.body1) return false
        return this.fields.autoplay
      },

      publications () {
        return this.fields.publications || []
      },
      userReviews () {
        return this.fields.user_reviews || []
      },

      showFeaturedProducts () {
        if (!this.fields) return false
        return this.fields.show_featured
      },
      featuredProductsColor () {
        if (!this.fields.featured_products_text_color) return 'black'
        return this.fields.featured_products_text_color.toLowerCase()
      },
      featuredContentColor () {
        if (!this.fields.featured_content_color) return 'black'
        return this.fields.featured_content_color.toLowerCase()
      },
      featuredContentBlocks () {
        if (!this.fields.featured_content_images) return []
        return this.fields.featured_content_images
      },
      featuredLink () {
        if (!this.fields.featured_content_link) return ''
        return this.$prismic.linkResolver(this.fields.featured_content_link)
      },
      featuredIds () {
        if (!this.fields.featured_products) return []
        return this.fields.featured_products.map(p => p.product.id)
      },
      featuredProducts () {
        return this.$store.getters.filteredProducts(this.featuredIds)
      },
      first () {
        return this.$store.state.journal[0]
      },
      posts () {
        return this.$store.state.journal.slice(1, 3)
      },
      hasSubscribed () {
        return this.$store.state.hasSubscribed
      },
      promoLink () {
        if (!this.fields.promo_link) return ''
        return this.$prismic.linkResolver(this.fields.promo_link)
      },
      signupVisible: {
        get () {
          return this.$store.state.signupVisible
        },
        set (newVal) {
          this.$store.commit('TOGGLE_SIGNUP', newVal)
        }
      },
      otherFormVisible () {
        return this.fields && this.fields.sample_visible &&
          this.fields.sample_visible === 'yes'
      },
      sampleFormComplete: {
        get () {
          return this.$store.state.sampleFormComplete
        },
        set (newVal) {
          this.$store.commit('SAMPLE_FORM_COMPLETE', newVal)
          this.$store.commit('SAMPLE_FORM_SEEN')
        }
      },
      instaToken () {
        return this.fields && this.fields.instagram_token
      },
      instaMsg () {
        return this.staticText('general', this.instaErr ? 'instaError' : 'loading')
      },
      instaPosts () {
        return store.state.instaData.slice(0, 6)
      },
      instaErr () {
        return store.state.instaErr
      },

      slidesOver () {
        if (!this.prodSlider) return false

        return this.prodSlider.track.details.slidesLength >= 1
      },

      // SEO
      seoTitle () {
        return (this.fields && this.fields.seo_title) || 'Sensory-rich skincare products - Imersa'
      },
      seoDescription () {
        return (this.fields && this.fields.seo_description) || mainTitle
      },
      seoImage () {
        return this.fields && this.fields.seo_image
      },

      ...mapGetters(['staticText', 'localeLink'])
    },
    methods: {
      next () {
        if (!this.prodSlider) return
        this.prodSlider.next()
      },
      prev () {
        if (!this.prodSlider) return
        this.prodSlider.prev()
      },
      async loadProducts () {
        await store.dispatch('getProducts')
        this.$nextTick(() => {
          // console.log(this.$refs.ps)
          this.prodSlider = new KeenSlider(this.$refs.ps, {
            slides: {
              perView: 1.5,
              spacing: 0,
            },
            breakpoints: {
              '(min-width: 640px)': {
                slides: {
                  perView: 2.5,
                  spacing: 0,
                }
              },
              '(min-width: 1024px)': {
                slides: {
                  perView: 3.5,
                  spacing: 0,
                }
              },
              '(min-width: 1250px)': {
                slides: {
                  perView: 4,
                  spacing: 0,
                }
              }
            }
          })
        })
      },
      loadJournal () {
        store.dispatch('getJournal')
      },
      loadInsta () {
        store.dispatch('getInstaData')
      },
      isVid (doc) {
        return doc.slice_type && doc.slice_type === 'video'
      },
      isImg (doc) {
        return !doc.slice_type || doc.slice_type === 'image'
      },
      isMobVid (doc) {
        return doc.mobile_type && doc.mobile_type === 'video'
      },
      poster (doc = {}) {
        if (!doc.primary) return
        return doc.primary.poster && doc.primary.poster.tablet.url
      },
      showSignup () {
        this.signupVisible = true
      },
      hideSignup () {
        this.signupVisible = false
      },
      toggleSampleForm () {
        this.showSampleForm = true
      },
      closeSampleForm () {
        this.showSampleForm = false
        this.$store.commit('SAMPLE_FORM_SEEN')
      },
      completeSampleForm () {
        this.showSampleForm = false
        this.sampleFormComplete = true
        const { other } = this.$refs
        if (other) {
          other.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    },
    watch: {
      $route (newVal) {
        if (newVal.path.includes('subscribe')) {
          this.showSignup()
        } else {
          this.hideSignup()
        }
      }
    },
    beforeMount () {
      this.canPlayVideo = !!document.createElement('video').canPlayType
    },
    mounted () {
      this.$store.dispatch('noVersions', 'home')
      if (this.$route.path.includes('subscribe')) {
        this.showSignup()
        return
      }

      const seen = this.$store.state.sampleFormSeen
      if (seen) return
      setTimeout(() => {
        if (!this.fields) return
        if (this.otherFormVisible && !this.sampleFormComplete) {
          this.showSampleForm = true
        }
      }, 5.5 * 1000)
    },
    components: { ProductGridItem, JournalGridItem, InstaPost, Campaign, UseForm, OrderSample, AnchorSwitch, HeroCarousel }
  }
</script>
