export default {
  CHANGE_LOCALE (state, locale) {
    state.locale = locale
  },
  SET_ALTERNATE_VERSIONS (state, doc = {}) {
    state.alternateLanguages = doc.alternate_languages || []
  },
  GET_SINGLE (state, { name, data }) {
    state.singles = { ...state.singles, [name]: data }
  },
  GET_PAGE (state, { slug, data }) {
    state.pages = { ...state.pages, [slug]: data }
  },
  GET_CAMPAIGN (state, { slug, data }) {
    state.campaigns = { ...state.campaigns, [slug]: data }
  },
  SET_PRODUCTS (state, { results, total_results_size: prodCount }) {
    state.shop = results
    state.prodCount = prodCount
  },
  SET_PRODUCT (state, data) {
    state.shop.push(...data)
  },

  CHANGE_INGREDIENTS_LANG (state, lang = 0) {
    state.ingredientsLang = lang
  },
  USER_ADD_TO_CART (state) {
    state.userAddToCart = true
  },
  CART_VISIBLE (state, bool) {
    state.cartVisible = bool
  },
  SET_CHECKOUT_ID (state, { id }) {
    state.checkoutId = id.value
    storeString('imersa-checkout', state.checkoutId)
  },
  SET_CHECKOUT (state, checkout) {
    if (!checkout) {
      state.checkoutId = null
      return
    }
    // if checkout is complete, replace it
    if (checkout.order || checkout.completedAt || checkout.orderStatusUrl) {
      state.checkoutId = null
      rmLocal('imersa-checkout')
      state.orders.unshift(checkout)
      if (state.orders.length) {
        storeJson('imersa-orders', state.orders)
      }
      return
    }
    state.checkout = checkout
  },
  CHECKOUT_LOADED (state) {
    state.checkoutLoaded = true
  },

  SET_JOURNAL (state, results) {
    state.journal.push(...results)
  },
  SET_NEXTPAGE (state, link) {
    state.nextPage = link
  },
  SET_JOURNAL_POST (state, data) {
    state.journalSingle = data[0]
  },
  SET_PRESS_ITEMS (state, data) {
    state.press = data
  },

  TOGGLE_SIGNUP (state, val) {
    state.signupVisible = val
  },
  TOGGLE_ANNOUNCEMENT (state, val) {
    state.announcement = val
  },
  TOGGLE_COOKIES (state, val) {
    state.cookiesvisible = val
  },

  SAMPLE_FORM_COMPLETE (state, val) {
    state.sampleFormComplete = val
    if (val) {
      storeString('imersa-samples-ordered', new Date())
    }
  },
  SAMPLE_FORM_SEEN (state) {
    state.sampleFormSeen = true
    storeString('imersa-samples-seen', 'seen')
  },

  SET_FONT_COLOR (state, color) {
    state.fontColor = color
  },

  START_REQUEST (state) {
    state.httpRequestsActive++
  },
  END_REQUEST (state) {
    if (state.httpRequestsActive === 0) return
    state.httpRequestsActive--
  },

  HTTP_ERROR (state, err) {
    let { response } = err
    if (typeof err === 'string') {
      err = { message: err }
    }
    state.httpErrors.push(response || err)
  },

  SUBSCRIBED (state) {
    state.hasSubscribed = true
  },

  INSTA_DATA (state, data) {
    state.instaData = data
  },
  INSTA_ERR (state) {
    state.instaErr = true
  }
}

function storeString (key, str) {
  if (!window.localStorage) return
  window.localStorage.setItem(key, str)
}

function storeJson (key, data) {
  if (!window.localStorage) return
  window.localStorage.setItem(key, JSON.stringify(data))
}

function rmLocal (key) {
  if (!window.localStorage) return
  window.localStorage.removeItem(key)
}
