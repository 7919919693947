<template>
  <div id="app" :class="bgColor" class="cf transition-bg tracked-narrow">
    <announcement/>
    <app-header/>

    <main class="mt-header app-main-height">
      <router-view/>
    </main>
    <app-footer/>
    <cookie-notice/>
    <!-- <cart-popup/> -->
  </div>
</template>

<script>
  import AppHeader from '@/components/AppHeader'
  import AppFooter from '@/components/AppFooter'
  import Announcement from '@/components/Announcement'
  import CookieNotice from '@/components/CookieNotice'
  // import CartPopup from '@/components/CartPopup'
  import { mapState, mapGetters, mapActions } from 'vuex'

  export default {
    name: 'App',
    metaInfo: {
      title: 'Sensory-rich skincare products',
      titleTemplate (chunk) {
        return chunk ? `${chunk} - Imersa` : 'Imersa'
      },
      htmlAttrs: {
        lang: window.location.pathname.startsWith('/it') ? 'it' : 'en'
      },
      meta: [
        {
          property: 'og:image',
          content: require('./assets/Imersa-products.jpg'),
          vmid: 'og:image'
        }
      ]
    },
    data () {
      return {
        newCartItem: false
      }
    },
    computed: {
      loaded () {
        return !!this.$store.state.singles['homepage']
      },
      bgColor () {
        if (!this.loaded) return 'o-0'
        return this.$route.meta.background || 'bg-white'
      },

      ...mapState(['checkoutLoaded']),
      ...mapGetters(['cartCount'])
    },
    methods: {
      ...mapActions(['getGlobal', 'getCheckout'])
    },
    mounted () {
      if (window.navigator.userAgent === 'ReactSnap') return

      // just the browser stuff
      window.lazySizes && window.lazySizes.init()
      this.getGlobal()
      this.getCheckout()
    },
    components: { AppHeader, AppFooter, Announcement, CookieNotice }
  }
</script>

<style>
  @import './style/global.css';
</style>
