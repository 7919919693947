<template>
  <div class="group">
    <router-link :to="url" class="link">
      <div ref="el" @lazybeforeunveil="show" class="lazyloading slow">
        <!-- <div :data-bg="img" class="lazyload cover bg-center aspect-ratio--5x7"></div> -->
        <div class="relative">
          <app-img :src="img" class="cover bg-center aspect-ratio--5x7" />
          <app-img v-if="altImg" :src="altImg" class="absolute inset-0 cover bg-center aspect-ratio--5x7 opacity-0 notouch:group-hover:opacity-100 transition-opacity duration-300" />

          <div v-if="discount" class="absolute top-0 left-0 m-4">
            <div class="bg-red px-3 py-1 rounded-full text-white text-sm">-{{ discount }}%</div>
          </div>
        </div>
        <div class="serif tc ttu f18 tracked mv3 title whitespace-pre-line">
          <!-- <p class="ma0 hidden">N<sup>O</sup> {{ data.number }}</p> -->
          <prismic-rich-text :field="data.display_title" />
        </div>
        <div v-if="intro" class="prismic tc f15 mb2 italic serif gray">
          <prismic-rich-text :field="intro" class="whitespace-normal"/>
        </div>
        <div v-if="rating" class="text-2xs text-center mb-2">
          <div class="Stars" :style="`--rating: ${rating.ratingValue};`" :aria-label="`${rating.ratingValue} out of 5 stars`"></div>
        </div>
        <p v-if="noPrice" class="f7 tc ma0 tracked ttu pt1">{{ staticText('product', 'shop') }}</p>
        <p v-else-if="unavailable" class="f7 tc ma0 tracked ttu pt2">{{ staticText('product', 'unavailable') }}</p>
        <p v-else class="f17 f18-l tc ma0 tracked">
          <template v-if="comparePrice">
            <span class="red strike">{{ comparePrice }}</span>&nbsp;
          </template>
          <span>{{ priceString }}</span>
        </p>
      </div>
    </router-link>
    <div v-if="btn && !unavailable" class="text-center mt-4 notouch:opacity-60 group-hover:opacity-100 transition-opacity duration-300">
      <button @click="add" class="btn py-3 px-8 bg-transparent pointer sans-serif tracked f13 lh-copy whitespace-pre-line">{{ staticText('product', 'add') }}</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProductGridItem',
    props: {
      product: {
        type: Object,
        require: true
      },
      btn: Boolean,
      noPrice: Boolean
    },
    computed: {
      data () {
        return this.product.data
      },
      url () {
        return `/${this.$route.params.locale || 'en'}/products/${this.product.uid}`
      },
      shopProduct () {
        return this.data.product
      },
      rating () {
        return this.data.reviews ? this.data.reviews.rating : null
      },
      reviews () {
        return this.data.reviews ? this.data.reviews.reviews : []
      },
      firstVariant () {
        return this.shopProduct && this.shopProduct.variants && this.shopProduct.variants[0]
      },
      variantId () {
        return this.firstVariant && btoa(this.firstVariant.admin_graphql_api_id)
      },
      unavailable () {
        if (!this.firstVariant) return true
        if (this.firstVariant.inventory_policy !== 'deny') return false
        return this.firstVariant.inventory_quantity < 1
      },
      priceString () {
        if (!this.firstVariant) return '---'
        const p = this.firstVariant.price
        const num = Number(p || 0)
        return `${num.toLocaleString()}€`
      },
      comparePrice () {
        if (!this.firstVariant) return false
        const p = this.firstVariant.compare_at_price
        if (p && p !== this.firstVariant.price) {
          const num = Number(p || 0)
          return `${num.toLocaleString()}€`
        }
      },
      discount () {
        if (!this.firstVariant) return 0
        const p = Number(this.firstVariant.price)
        const c = Number(this.firstVariant.compare_at_price)
        if (c && p !== c) {
          const pc = Math.round(((c - p) / c) * 100)
          return pc
        }
        return 0
      },
      img () {
        return this.data.thumbnail
      },
      altImg () {
        return this.data.thumbnail_cutout
      },
      intro () {
        if (!this.data) return
        const txt = this.data.intro_text
        if (this.$prismic.richTextAsPlain(txt) === '') return
        return txt
      },

      ...mapGetters(['staticText'])
    },
    methods: {
      show () {
        const { el } = this.$refs
        window.requestAnimationFrame(() => {
          el.classList.add('lazyloaded')
          el.classList.remove('lazyloading')
        })
      },
      add () {
        this.$ma && this.$ma.trackEvent({
          fb_event: 'AddToCart',
          type: 'product',
          value: parseFloat(this.firstVariant.price),
          currency: 'EUR',
          ids: [this.shopProduct.id, this.firstVariant.id]
        }, ['ga'])
        this.$store.dispatch('addToCart', [{
          variantId: this.variantId,
          quantity: 1
        }])
      }
    }
  }
</script>
