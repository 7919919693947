import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes'
import 'keen-slider/keen-slider.min.css'

import Vue from 'vue'
import App from '@/App'
import AppImg from '@/components/AppImg'
import router from '@/router'
import store from '@/store'
import PrismicVue from 'prismic-vue'
import linkResolver from '@/link-resolver'
import Vue2TouchEvents from 'vue2-touch-events'
import smoothscroll from 'smoothscroll-polyfill'
// import { inSSR } from '@/util'

smoothscroll.polyfill()

// make sure ssr works (init() in App.vue)
window.lazySizesConfig = window.lazySizes.cfg || {}
window.lazySizesConfig.init = false

Vue.config.productionTip = false

// if (process.env.NODE_ENV === 'production' && !inSSR()) {
//   // load external scripts
//   const loadJS = window.loadJS || function () {}
//   loadJS('https://static.cdn.prismic.io/prismic.min.js')
//   loadJS('https://cdn.subscribers.com/assets/subscribers.js')
// }

Vue.use(PrismicVue, {
  endpoint: process.env.VUE_APP_PRISMIC_URL,
  linkResolver
})

Vue.use(Vue2TouchEvents)

Vue.component('app-img', AppImg)

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

router.onReady(() => {
  app.$mount('#app')
})

window.snapSaveState = () => {
  // enable client-side hydration once the page has been prerendered
  document.querySelector('#app').setAttribute('data-server-rendered', 'true')
}
