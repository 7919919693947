<template>
  <div @click.self="close" class="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-5 bg-white-30">
    <div class="bg-blue white shadow-4 relative w-100 mw7 max-vh-100 mv-auto overflow-auto mh3-m vh-100 h-auto-ns">
      <div class="ph3 pv5 ph5-l pt5-l pb4-l tc">
        <p v-text="fields.sample_form_title" class="f30 serif tracked ttu mb3 mh4"></p>
        <p v-text="fields.sample_form_description" class="f17 measure center"></p>
        <form @submit.prevent="submit">
          <div class="flex items-end mt4">
            <div class="w-50 pr2">
              <!-- <label class="db f7 ttu tracked mb1 tl">{{ staticText('samples', 'nameLabel') }}</label> -->
              <input v-model="form.name" ref="first" type="text" :placeholder="staticText('samples', 'name')" class="input-reset w-100 bg-transparent ba white b--white pa3">
            </div>
            <div class="w-50 pl2">
              <input v-model="form.lastName" type="text" :placeholder="staticText('samples', 'lastname')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
            </div>
          </div>
          <div class="mt3">
            <input v-model="form.email" ref="email" type="email" :placeholder="staticText('samples', 'email')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
          </div>
          <div class="mt3 flex items-end">
            <div class="w-50 pr2">
              <input v-model="form.address" type="text" :placeholder="staticText('samples', 'address')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
            </div>
            <div class="w-50 pl2">
              <input v-model="form.number" type="text" :placeholder="staticText('samples', 'number')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
            </div>
          </div>
          <div class="mt3 flex items-end">
            <div class="w-50 pr2">
              <input v-model="form.city" type="text" :placeholder="staticText('samples', 'city')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
            </div>
            <div class="w-50 pl2">
              <input v-model="form.zip" type="text" :placeholder="staticText('samples', 'zip')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
            </div>
          </div>
          <div class="mt3 flex-ns items-end">
            <!-- <div class="w-50-ns pr2-ns"> -->
            <div class="w-100">
              <input v-model="form.state" type="text" :placeholder="staticText('samples', 'state')" class="input-reset w-100 bg-transparent ba white b--white pa3" required>
            </div>
            <!-- <div class="w-50-ns pl2-ns mt3 mt0-ns">
              <input v-model="form.note" type="text" :placeholder="staticText('samples', 'note')" class="input-reset w-100 bg-transparent ba white b--white pa3">
            </div> -->
          </div>
          <div class="mt4">
            <p v-if="submitErr" v-html="submitErr" class="b"></p>
          </div>
          <div class="mt4 tc">
            <input type="submit" :value="fields.sample_action" class="btn pointer input-reset bg-transparent ba white pv3 ph4 b--white sans-serif tracked ttu f13 br0">
          </div>
          <div class="mt4 mb2">
            <p class="f7">
              <span @click.stop="close">
                <router-link :to="staticText('general', 'privacyLink')">{{ staticText('general', 'privacy') }}</router-link>
              </span>
            </p>
          </div>
        </form>
      </div>
      <div class="absolute top-0 right-0 ma3 ph1">
        <a @click="close" class="f3 lh-solid pointer">&times;</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { jsonp } from '@/util'
  const mcUrl = process.env.VUE_APP_MC_URL
  const mcUser = process.env.VUE_APP_MC_USER

  export default {
    name: 'OrderSample',
    props: {
      fields: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        form: {
          name: null,
          lastName: null,
          email: null,
          address: null,
          number: null,
          city: null,
          zip: null,
          state: null,
          note: null,
          country: 'IT'
        },
        submitErr: null
      }
    },
    computed: {
      complete () {
        return this.$store.state.sampleFormComplete
      },
      listId () {
        return this.fields.sample_listid
      },

      ...mapGetters(['staticText'])
    },
    methods: {
      submit () {
        this.submitErr = false
        if (!this.form.email) {
          this.submitErr = 'Please include your email address'
          return
        }
        this.$store.commit('START_REQUEST')
        jsonp(mcUrl, {
          u: mcUser,
          id: this.listId,
          EMAIL: this.form.email,
          FNAME: this.form.name,
          LNAME: this.form.lastName,
          ADDRESS: this.form.address,
          CIVICO: this.form.number,
          CITY: this.form.city,
          CAP: this.form.zip,
          STATE: this.form.state,
          COUNTRY: this.form.country,
          NOTE: this.form.note
        }, { param: 'c' }, (err, data) => {
          if (err) {
            console.log(err)
            this.submitErr = err.message
          } else {
            if (data.result === 'error') {
              this.submitErr = data.msg
            } else {
              this.$emit('complete')
            }
          }
          this.$store.commit('END_REQUEST')
        })
      },
      close () {
        this.$emit('close')
      }
    },
    created () {
      document.scrollingElement.classList.add('overflow-hidden')
    },
    destroyed () {
      document.scrollingElement.classList.remove('overflow-hidden')
    }
  }
</script>
