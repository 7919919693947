<template>
  <div v-if="visible" class="fixed bottom-0 left-0 pb3 w-2/3 mw9 px-4 ph5-l z-1">
    <div class="bg-light-red red shadow-4 f15 pv3 px-4 md:px-8 flex-ns justify-between items-center">
      <div class="mr-2 md:mr-4">
        <span>{{ staticText('general', 'cookies') }} <router-link :to="staticText('general', 'cookieLink')" class="dark-gray">{{ staticText('general', 'cookieLinkText') }}</router-link>.</span>
      </div>
      <div class="flex">
        <button @click="decline" class="red ml3-m f15 pointer">Decline</button>
        <button @click="consent" class="red ml3 f15 pointer">Accept</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Cookies from 'js-cookie'
  import startAnalytics from '@/analytics'
  const key = 'imersa-cookie-consent'

  export default {
    name: 'CookieNotice',
    computed: {
      visible: {
        get () {
          return this.$store.state.cookiesvisible
        },
        set (newVal) {
          this.$store.commit('TOGGLE_COOKIES', newVal)
        }
      },
      ...mapGetters(['staticText'])
    },
    methods: {
      start () {
        startAnalytics()
        // this.$nextTick(() => {
        //   this.$ma.trackView({ viewName: this.$route.name })
        // })
      },

      consent () {
        Cookies.set(key, true, { expires: 365 / 4 })
        this.visible = false
        this.start()
      },
      decline () {
        this.visible = false
      }
    },
    mounted () {
      let consent = Cookies.get(key)
      if (!consent && window.navigator.userAgent !== 'ReactSnap') {
        this.visible = true
        // setup cookies
        // fetch('https://shop.imersa.it/cart.js', { credentials: 'include', mode: 'no-cors' }).then((res) => {
        //   this.visible = true
        //   res.text().then((text) => {
        //     console.log(text)
        //   })
        // })
      } else {
        this.start()
      }
    }
  }
</script>
