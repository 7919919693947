<template>
  <div class="cart z-20 bg-light-red shadow-lg">
    <div class="py-3 px-8 absolute w-100 bg-white lh-copy z-2 bb b--light-silver">
      <a @click="close" class="pointer absolute left-0 top-0 pv3 ph3 mt2 ml2">
        <img src="~../assets/chevron-left.svg" width="10" height="10" />
      </a>
      <p class="f27 serif tracked ma0 ttu tc lh-copy pb-px">{{ staticText('cart', 'cart') }}</p>
    </div>
    <div class="cart-height overflow-auto mb-[66px] flex flex-col max-h-fill">
      <div v-if="!empty" class="w-full center cf bg-white pt-header">
        <div v-if="productsLoaded" class="mw8">
          <div v-for="(product, i) in items" :key="i" :class="{ bt: i > 0 }" class="b--light-silver ph3">
            <div class="flex relative ph3">
              <div :key="product.id" class="pv4 pv3-l w-25">
                <router-link :to="productUrl(itemsData[i])" class="link">
                  <div :data-bg="cartImg(i)" class="lazyload aspect-ratio--5x7 bg-center cover w-100"></div>
                </router-link>
              </div>
              <div v-if="itemsData[i]" class="pl4 pv4 w-75">
                <div class="serif">
                  <router-link :to="productUrl(itemsData[i])" class="link">
                    <p v-if="itemsData[i].data.number" class="f18 ma0 tracked hidden">N<sup>O</sup> {{ itemsData[i].data.number }}</p>
                    <prismic-rich-text :field="itemsData[i].data.display_title" class="prismic tracked ma0 f18 ttu" />
                    <div class="f18 ma0 tracked">
                      <span v-if="itemsData[i].data.vol_ml">{{ itemsData[i].data.vol_ml }}<sup>{{ itemsData[i].data.unit }}</sup></span>
                      <span v-if="itemsData[i].data.vol_oz">&puncsp;{{ itemsData[i].data.vol_oz }}<sup>OZ</sup></span>
                    </div>
                  </router-link>
                </div>

                <div class="flex pt3 mt2">
                  <div class="mr4">
                    <p class="f7 tracked ttu mb2">{{ staticText('cart', 'price') }}</p>
                    <div>
                      <p class="f17 ma0 sans-serif tracked">
                        <span v-if="comparePrice(product)">
                           <span class="red strike">{{ comparePrice(product) }}</span>&nbsp;
                        </span>
                        {{ price(product) }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p class="f7 tracked ttu mb2">{{ staticText('cart', 'qty') }}</p>
                    <div class="f17">
                      <a @click="updateLine(product, -1)" class="pointer dib f20 lh-solid v-top mr1">&ndash;</a>
                      <span class="ma0 ph2 dib">{{ product.quantity }}</span>
                      <a @click="updateLine(product, 1)" class="pointer dib f20 lh-solid">+</a>
                    </div>
                  </div>
                </div>

                <div class="absolute top-0 right-0 mt3 mr3">
                  <a @click="removeLine(product)" class="pointer dib pa1 f22 lh-copy" title="Remove item">&times;</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mw8 pv4">
          <p class="tc gray f17 ttu">{{ staticText('general', 'loading') }}</p>
        </div>
      </div>

      <div v-if="!empty" class="bg-light-red pa3 sticky bottom-0">
        <div class="mw8 ph3 pt2 pb3">
          <div class="flex justify-between">
            <div class="w-100">
              <div v-if="discounts.length" class="f18">
                <p class="silver f14 mb1">Discounts</p>
                <div v-for="discount in discounts" :key="discount.title" class="flex justify-between items-center mb4">
                  <p class="mb0 f14">
                    <img :data-src="require('../assets/tags-filled.svg')" class="lazyload inline-block" style="width:1em;height:1em;vertical-align:top" />
                    <span> {{ discount.title || discount.code }}</span>
                  </p>
                  <p>- {{ formatMoney(discountAmount(discount.title || discount.code)) }}</p>
                </div>
              </div>
              <div class="flex justify-between f18 ttu">
                <p class="serif tracked">{{ staticText('cart', 'subtotal') }}</p>
                <p class="f17 sans-serif tracked">
                  <span v-if="discounts.length" class="gray f14 strike">{{ preDiscount }}</span>
                  <span>&nbsp; {{ formatMoney(subtotal) }}</span>
                </p>
              </div>
              <div class="scalapayWidget mb2" style="min-height:20px;">
                <scalapay-widget :amount="subtotal" :locale="locale2" min="5" max="" amountSelectorArray='[".totals .totals__subtotal-value"]' size="80px" logoSize="80%" priceColor="#000000" logoColor="#000000" logoAlignment="-6px" numberOfPayments="3" hideLogo="" hidePrice="" priceBoxSelector=".totals">
                </scalapay-widget>
              </div>
              <div>
                <p class="silver f14">{{ staticText('cart', 'shipping') }}</p>
              </div>
              <div class="pt3">
                <a @click.prevent="goToCheckout" :href="checkoutUrl" class="btn f13 tracked ttu dib w-100 pa3 ba tc pointer">{{ staticText('cart', 'checkout') }}</a>
              </div>
              <div class="pt3">
                <cart-buttons/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="pa3 bg-white min-vh-100 behind-head pt-header">
        <div class="mw8 ph3 mh2 mt5 pt3">
          <p class="tc f18" @click="close">{{ staticText('cart', 'empty') }} <router-link :to="localeLink('/collections/all')" class="color-inherit"> {{ staticText('cart', 'continueShopping') }}</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { formatMoney, openCheckout } from '@/util'
  import CartButtons from './CartButtons.vue'

  export default {
    name: 'Cart',
    computed: {
      checkout () {
        return this.$store.state.checkout
      },
      items () {
        return this.$store.getters.cartItems
      },
      products () {
        return this.$store.state.shop
      },
      itemsData () {
        return this.items.map((item) => {
          const enc = item.variant.product.id
          return this.products.find((p) => {
            if (!p.data.product) return
            return p.data.product.admin_graphql_api_id === enc
          })
        })
      },
      discounts () {
        return this.checkout.discountApplications || []
      },
      preDiscount () {
        if (!this.discounts.length || !this.items) return this.subTotal
        let amnt = this.items.reduce((a, c) => {
          return a + (c.quantity * Number(c.variant.priceV2?.amount))
        }, 0)
        return this.formatMoney(amnt)
      },
      subtotal () {
        return Number(this.checkout.subtotalPriceV2.amount)
      },
      empty () {
        return !this.items || !this.items.length
      },
      productsLoaded () {
        return !!this.products.length
      },
      locale () {
        return this.$store.state.locale
      },

      ...mapGetters(['checkoutUrl', 'staticText', 'localeLink', 'locale2'])
    },
    methods: {
      formatMoney,

      goToCheckout () {
        this.$ma && this.$ma.trackEvent({
          fb_event: 'InitiateCheckout'
        }, ['ga'])

        const locale = this.locale.split('-')
        openCheckout(this.checkoutUrl, locale[0])
      },
      removeLine ({ id }) {
        this.updateCart({
          id,
          quantity: 0
        })
      },
      updateLine ({ id, quantity }, qty) {
        this.updateCart({
          id,
          quantity: quantity + qty
        })
      },
      cartImg (idx) {
        if (!this.itemsData[idx]) return
        return this.itemsData[idx].data.thumbnail.url
      },
      price ({ variant }) {
        if (!variant) return '---'
        return `${Number(variant.priceV2.amount)}€`
      },
      comparePrice ({ variant }) {
        if (!variant) return false
        // different key to data from prismic
        const comp = variant.compareAtPrice
        const p = variant.price
        if (!comp || comp === p) return false
        return `${Number(comp)}€`
      },
      productUrl (data) {
        if (!data) return ''
        return this.localeLink(`/products/${data.uid}`)
      },
      close () {
        this.$emit('close')
      },
      discountAmount (title) {
        if (!title) return 0
        return this.items.reduce((a, c) => {
          let m = c.discountAllocations.find((d) => {
            return d.discountApplication.title === title ||
              d.discountApplication.code === title
          })
          return m ? a + Number(m.allocatedAmount.amount) : a
        }, 0)
      },

      ...mapActions(['updateCart', 'getProducts'])
    },
    mounted () {
      if (!this.products.length) {
        this.getProducts()
      }

      // let's check some carts
      // const { checkoutId } = this.$store.state
      // const { locale } = this.$route.params
      // this.$router.replace({
      //   name: 'cart',
      //   params: { locale },
      //   query: { id: checkoutId }
      // })
    },
    components: { CartButtons }
  }
</script>
