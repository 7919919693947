import Vue from 'vue'
import router from '@/router'
// import VueMultianalytics from 'vue-multianalytics'
import VueGtag from 'vue-gtag'
import { inSSR } from '@/util'

export default function start () {
  if (!inSSR()) {
    Vue.use(VueGtag, {
      config: { id: process.env.VUE_APP_ANALYTICS_ID }
    }, router)
  }
}
