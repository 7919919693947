import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Home from '@/views/Home'
import Preview from '@/views/Preview'
import Campaign from '@/views/Campaign'
import NotFound from '@/views/NotFound'

const Shop = () => import(/* webpackChunkName: "shop" */ '@/views/Shop')
const Product = () => import(/* webpackChunkName: "shop" */ '@/views/Product')
const Cart = () => import(/* webpackChunkName: "shop" */ '@/views/Cart')
const Page = () => import(/* webpackChunkName: "pages" */ '@/views/Page')
const Concept = () => import(/* webpackChunkName: "pages" */ '@/views/Concept')
const Science = () => import(/* webpackChunkName: "pages" */ '@/views/Science')
const Press = () => import(/* webpackChunkName: "pages" */ '@/views/Press')
const CustomerService = () => import(/* webpackChunkName: "pages" */ '@/views/CustomerService')
const Journal = () => import(/* webpackChunkName: "journal" */ '@/views/Journal')
const Post = () => import(/* webpackChunkName: "journal" */ '@/views/Post')

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/preview',
      name: 'preview',
      component: Preview
    },

    // locale based routes
    {
      path: '/:locale([a-z]{2})?',
      component: {
        name: 'Locale',
        template: '<router-view/>'
      },
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          // set by bannerColor
          meta: { color: 'red' },
          alias: 'subscribe'
        },
        {
          path: 'collections/:slug',
          name: 'shop',
          component: Shop,
          meta: { color: 'red' }
        },
        {
          path: 'products/:slug',
          name: 'product',
          component: Product,
          meta: { color: 'red' }
        },
        {
          path: 'cart',
          name: 'cart',
          component: Cart,
          meta: { color: 'red' }
        },
        {
          path: '(concept|storia)',
          name: 'concept',
          component: Concept,
          meta: { color: 'red' },
          alias: '/about'
        },
        {
          path: '(science-of-silk|scienza-della-seta)',
          name: 'science',
          component: Science,
          meta: { color: 'red' }
        },
        {
          path: 'journal',
          name: 'journal',
          component: Journal,
          meta: {
            color: 'blue',
            background: 'bg-light-red'
          }
        },
        {
          path: 'c/:slug',
          name: 'campaign',
          component: Campaign,
          meta: { hideHeader: true }
        },
        {
          path: 'journal/:year?/:month?/:day?/:slug',
          name: 'post',
          component: Post,
          meta: {
            color: 'blue',
            background: 'bg-light-red'
          }
        },
        {
          path: 'press',
          name: 'press',
          component: Press,
          meta: { color: 'blue' }
        },
        {
          path: 'pages/(customer-service|servizio-clienti)',
          name: 'customer-service',
          component: CustomerService,
          meta: { color: 'red' }
        },
        {
          path: 'pages/:slug',
          name: 'page',
          component: Page,
          meta: { color: 'red' }
        },

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
          meta: { color: 'black' }
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if ((to.path.includes('newsletter') && from.name === 'home') ||
    (from.path.includes('newsletter') && to.name === 'home')) {
      return
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
