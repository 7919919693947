import Vue from 'vue'
import Vuex from 'vuex'

import actions from '@/store/actions'
import mutations from '@/store/mutations'
import getters from '@/store/getters'
import locales from '@/store/locales'
import { getLocal } from '@/util'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locales,
    languages: [
      { title: 'English', code: 'en', locale: 'en-gb' },
      { title: 'Italiano', code: 'it', locale: 'it-it' }
    ],
    locale: window.location.pathname.startsWith('/it') ? 'it-it' : 'en-gb',
    // set alternate version(s) per route
    alternateLanguages: [],

    singles: {},
    pages: {},
    shop: [],
    prodCount: null,
    filterShop: 'all',
    ingredientsLang: 0,
    campaigns: {},

    userAddToCart: false,
    checkoutId: getLocal('imersa-checkout'),
    checkout: null,
    checkoutLoaded: false,
    orders: getLocal('imersa-orders', []),
    cartVisible: false,

    journal: [],
    nextPage: null,
    journalSingle: null,
    press: [],

    signupVisible: false,

    sampleFormComplete: false,
    sampleFormSeen: getLocal('imersa-samples-seen'),

    fontColor: 'mid-gray',

    httpRequestsActive: 0,
    httpErrors: [],

    hasSubscribed: false,
    cookieConsent: false,
    announcement: false,
    cookiesvisible: false,

    instaData: [],
    instaErr: false
  },

  actions,
  mutations,
  getters
})
