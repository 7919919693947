<template>
  <div class="relative carousel-height">
    <div class="w-full h-full keen-slider">
      <div v-for="(item, i) in visibleBanners" :key="i" class="w-full h-full relative keen-slider__slide flex-none">
        <div class="w-full h-full overflow-hidden">
          <div v-if="isVid(item) && canPlayVideo" :key="i + 'a'" class="lg:hidden relative overflow-hidden w-full h-full">
            <video @loadeddata="something" :poster="poster(item)" playsinline autoplay muted loop class="bg-video">
              <source v-if="item.primary.mobile_webm" :src="item.primary.mobile_webm.url" type="video/webm"/>
              <source v-if="item.primary.mobile_mp4" :src="item.primary.mobile_mp4.url" type="video/mp4"/>
            </video>
          </div>
          <app-img @onload="something" v-if="isImg(item)" :src="item.primary.mobile_image" :key="i + 'b'" :bg="false" class="lg:hidden object-cover object-center h-full w-full"/>

          <!-- desktop -->
          <div v-if="isVid(item) && canPlayVideo" :key="i + 'c'" class="hidden lg:block relative overflow-hidden h-full w-full">
            <video @loadeddata="something" :poster="poster(item)" playsinline autoplay muted loop class="bg-video">
              <source v-if="item.primary.webm" :src="item.primary.webm.url" type="video/webm"/>
              <source v-if="item.primary.mp4" :src="item.primary.mp4.url" type="video/mp4"/>
            </video>
          </div>
          <app-img @onload="something" v-if="isImg(item)" :src="item.primary.image" :key="i + 'd'" :bg="false" class="hidden lg:block object-cover object-center h-full w-full"/>

          <anchor-switch :url="bannerLink(item)" :class="bannerTextPosition(item)" class="absolute inset-0 z-1 pb-8 flex p-12 lg:p-16">
            <div :style="bannerColor(item)" class="max-w-screen-sm">
              <p v-if="bannerTitle(item)" v-html="bannerTitle(item)" class="f2 f1-l ma0 tracked leading-none whitespace-pre-line font-bold"></p>
              <p v-if="bannerSubtitle(item)" v-text="bannerSubtitle(item)" class="f18 mt2 pt2 tracked whitespace-pre-line underline font-bold"></p>
            </div>
          </anchor-switch>
        </div>
      </div>
    </div>

    <div v-if="visibleBanners.length > 1" class="text-white">
      <transition name="fade">
        <button @click="prev" class="absolute top-1/2 -translate-y-1/2 m-4 bg-opacity-50 hover:bg-opacity-100 rounded-full w-8 h-8 grid place-items-center">
          <svg width="7" height="20" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 21L1 10.5L8 0.5" stroke="white" stroke-width="3"/></svg>
        </button>
      </transition>

      <transition name="fade">
        <button @click="next" class="absolute top-1/2 right-0 -translate-y-1/2 m-4 bg-opacity-50 hover:bg-opacity-100 rounded-full w-8 h-8 grid place-items-center">
          <svg width="7" height="20" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="translate-x-px"><path d="M1 1L8 11.5L1 21.5" stroke="white" stroke-width="3"/></svg>
        </button>
      </transition>

      <div class="absolute left-0 bottom-0 w-full pointer-events-none flex items-center justify-center mb-4" aria-hidden="true">
        <div v-for="(_item, i) in visibleBanners" :key="i" @click="goTo(i)" :class="{ 'bg-white': i === idx }" class="border border-white rounded-full w-2 h-2 mx-2 pointer-events-auto cursor-pointer transition-colors"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import KeenSlider from 'keen-slider'
  import AnchorSwitch from './AnchorSwitch.js'

  export default {
    props: {
      banners: Array,
      autoplay: Boolean
    },
    data () {
      return {
        canPlayVideo: false,
        slider: null,
        idx: 0,
        ww: window.innerWidth,
        listener: null
      }
    },
    computed: {
      visibleBanners () {
        return this.banners?.filter((s) => {
          if (s.slice_label !== 'timed') return true
          const st = s.primary.start_date ? new Date(s.primary.start_date) : false
          const en = s.primary.end_date ? new Date(s.primary.end_date) : false
          if (!st) return true
          const afterStart = Date.now() >= st
          if (!afterStart) return false
          if (en) {
            const beforeEnd = Date.now() < en
            if (!beforeEnd) return false
          }
          return true
        })
      },
      firstImgHeight () {
        const first = this.visibleBanners?.find((item) => {
          return item.slice_type === 'image'
        })
        const img = first?.primary?.image
        const mob = first?.primary?.mobile_image

        const dr = (img?.dimensions?.height / img?.dimensions?.width) || 0
        const mr = (mob?.dimensions?.height / mob?.dimensions?.width) || 0

        if (this.ww >= 1024) {
          if (!img || !dr) return window.innerHeight - 122

          return window.innerWidth * dr
        } else {
          if (!mob || !mr) return window.innerHeight - 64

          return window.innerWidth * mr
        }
      }
    },
    methods: {
      timedSlide (slide = {}) {
        return slide.slice_label === 'timed'
      },
      goTo (idx) {
        if (!this.slider) return
        this.slider.moveToIdx(idx)
      },
      next () {
        if (!this.slider) return
        this.slider.next()
      },
      prev () {
        if (!this.slider) return
        this.slider.prev()
      },
      something () {
        this.slider && this.slider.update()
      },
      isVid (doc = {}) {
        return doc.slice_type === 'video'
      },
      isImg (doc = {}) {
        return !doc.slice_type || doc.slice_type === 'image'
      },
      isMobVid (doc = {}) {
        return doc.mobile_type === 'video'
      },
      poster (doc = {}) {
        if (!doc.primary) return
        return doc.primary.poster && doc.primary.poster.tablet.url
      },
      bannerLink (doc = {}) {
        if (!doc.primary || !doc.primary.link) return ''

        return this.$prismic.linkResolver(doc.primary.link)
      },
      bannerTitle (doc = {}) {
        return doc.primary && doc.primary.title
      },
      bannerSubtitle (doc = {}) {
        return doc.primary && doc.primary.subtitle
      },
      bannerColor (doc = {}) {
        return {
          color: (doc.primary && doc.primary.color) || 'white'
        }
      },
      bannerTextPosition (doc = {}) {
        const xopts = {
          Centre: 'justify-center',
          Left: 'justify-start',
          Right: 'justify-end'
        }
        const yopts = {
          Centre: 'items-center',
          Top: 'items-start',
          Bottom: 'items-end'
        }
        const aopts = {
          Centre: 'text-center',
          Left: 'text-left',
          Right: 'text-right'
        }
        const x = (doc.primary && doc.primary.text_x) || 'Centre'
        const y = (doc.primary && doc.primary.text_y) || 'Centre'

        return [xopts[x], yopts[y], aopts[x]]
      },
      setBannerHeight () {
        this.ww = window.innerWidth

        this.$nextTick(() => {
          document.documentElement.style.setProperty('--ch', this.firstImgHeight + "px");
          // console.log('set banner height', this.firstImgHeight)
        })
      }
    },
    async mounted () {
      this.setBannerHeight()
      await sleep(900)
      this.slider = new KeenSlider(this.$el, { loop: true }, [
        (slider) => {
          if (!this.autoplay) return

          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 3000)
          }
          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on('dragStarted', clearNextTimeout)
          slider.on('animationEnded', nextTimeout)
          slider.on('updated', nextTimeout)
        }
      ])
      this.slider.update()
      this.slider.on('slideChanged', (slider) => {
        const pos = slider.track.details.abs % slider.slides.length
        this.idx = pos
      })

      window.addEventListener('resize',this.setBannerHeight)
    },
    beforeMount () {
      this.canPlayVideo = !!document.createElement('video').canPlayType
    },
    destroyed () {
      window.removeEventListener('resize', this.setBannerHeight)
    },
    components: { AnchorSwitch }
  }

  function sleep (ms = 1000) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
</script>
