<template>
  <div v-if="visible" class="fixed left-0 top-0 w-100 z-4">
    <div :class="{ 'o-0': leaving }" class="vh-100 transition-o pointer bg-white">
      <div v-if="isVideo && canPlayVideo" class="relative overflow-hidden h-100 w-100">
        <video ref="vid" playsinline autoplay muted class="cover bg-center bg-video">
          <source :src="asset.primary.large_webm.url" type="video/webm"/>
          <source :src="asset.primary.large_mp4.url" type="video/mp4"/>
        </video>
      </div>
      <app-img v-if="!isVideo" :src="asset.primary.image" class="cover bg-center h-100 w-100" />
    </div>
    <div @click="leave" :class="{ 'o-0': leaving }" class="absolute top-0 w-100 vh-100 flex items-center justify-center tc ph4 transition-o">
      <div tag="div" class="z-1 pointer mb4 white">
        <p v-html="bannerText" class="f2 f1-m f-6-l ma0 serif tracked pre"></p>
        <p v-text="campaign.data.banner_subtitle" class="f7 mt2 pt2 tracked"></p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Campaign',
    data () {
      return {
        visible: false,
        leaving: false,
        canPlayVideo: !!document.createElement('video').canPlayType
      }
    },
    computed: {
      campaign () {
        return this.$store.state.singles['campaign'] || {}
      },
      asset () {
        if (!this.visible) return
        const i = Math.floor(Math.random() * this.campaign.data.body.length)
        return this.campaign.data.body[i]
      },
      isVideo () {
        if (!this.visible) return
        return this.asset.slice_type === 'video'
      },
      bannerText () {
        if (!this.visible) return
        if (!this.campaign.data.banner_text) return
        return this.campaign.data.banner_text.replace('No', 'N<sup>O</sup>')
      }
    },
    methods: {
      leave () {
        this.leaving = true
        setTimeout(() => {
          this.visible = false
        }, 1200)
      }
    },
    watch: {
      campaign ({ data }) {
        if (data && data.visibility === 'visible') {
          this.visible = true
          this.$nextTick(() => {
            this.$refs.vid.addEventListener('ended', (e) => {
              this.leave()
            })
          })
        }
      }
    }
  }
</script>
