<template>
  <div class="w-50 ph2 ph4-l db w-third-ns dip mb3 mb5-l">
    <div>
      <a :href="post.permalink" target="_blank" class="link">
        <div :data-bg="imgUrl" class="aspect-ratio--1x1 aspect-ratio--5x7-l cover bg-center lazyload"></div>
      </a>
      <h2 v-text="caption" class="db-l f14 f18-l serif tracked ttu tc normal mt3 mb1 mt4-m mb3-m"></h2>
      <p class="dn db-l f7 tracked ttu tc ma0 pt1">
        <a :href="post.permalink" rel="noopener" target="_blank" class="link">{{ staticText('general', 'view') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'InstaPost',
    props: {
      post: {
        type: Object,
        required: true
      }
    },
    computed: {
      caption () {
        const l = 55
        if (this.post.caption.length > l) {
          return this.post.caption.substring(0, l) + '...'
        }
        return this.post.caption
      },
      imgUrl () {
        const base = process.env.VUE_APP_INSTA_DATA
        const id = this.post.id
        return `${base}/assets/instagram/media/${id}.jpg`
      },

      ...mapGetters(['staticText'])
    }
  }
</script>
