<template>
  <div>
    <transition name="fade">
      <div v-if="show" @click.self="close" class="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-end items-center-m z-5 bg-white-50">
        <div class="bg-light-red red shadow-4 relative w-100 mw7 max-vh-100 overflow-auto mh3-m h-auto">
          <div class="ph4 pv5 tc">
            <p class="mb5"><logo title="Imersa" /></p>
            <p class="mb2">{{ staticText('switch', 'intro') }}</p>
            <p class="mb3">
              <a @click="changeLang" class="underline pointer">
                {{ staticText('switch', 'cta') }}
              </a>
            </p>
            <p>
              <a @click="close" class="f14 pointer">
                &times; {{ staticText('switch', 'no') }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Cookies from 'js-cookie'
  import Logo from '@/components/icons/Logo'

  const key = 'imersa.lang'

  export default {
    name: 'SwitchLang',
    data () {
      return {
        show: false
      }
    },
    computed: {
      locales () {
        return this.$store.state.languages
      },
      locale () {
        return this.$store.state.locale
      },
      switchTo () {
        return this.locales.find(l => l.locale !== this.locale)
      },

      ...mapGetters(['staticText'])
    },
    methods: {
      changeLang () {
        if (!this.switchTo) return
        this.$emit('change', this.switchTo.locale)
        this.langChosen()
      },
      close () {
        this.show = false
        this.langChosen()
      },
      langChosen () {
        Cookies.set(key, this.locale, { expires: 365 / 4 })
      }
    },
    mounted () {
      // if (Cookies.get(key)) return
      // setTimeout(() => {
      //   const lang = (navigator.languages && navigator.languages[0]) ||
      //     navigator.language ||
      //     navigator.userLanguage
      //   const code = lang.split('-')[0]
      //   const avail = this.switchTo && this.switchTo.code
      //   if (code === avail) {
      //     this.show = true
      //   }
      // }, 4000)
    },
    components: { Logo }
  }
</script>
