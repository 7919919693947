import linkResolver from '@/link-resolver'

export default {
  orderedProducts ({ shop }) {
    if (!shop || !shop.length) return []

    return shop.sort((a, b) => {
      const an = Number(a.data.number)
      const bn = Number(b.data.number)
      return an - bn
    })
  },
  filteredProducts: (state) => (ids = []) => {
    return ids.map((id) => {
      return state.shop.find((p) => p.id === id)
    }).filter(p => !!p)
  },
  filteredPosts: (state) => (ids) => {
    return state.journal.filter((p) => {
      return ids.includes(p.id)
    })
  },
  cartItems ({ checkout }) {
    return checkout && checkout.lineItems
  },
  cartCount (_, { cartItems }) {
    return cartItems && cartItems.reduce((a, c) => a + c.quantity, 0)
  },
  checkoutUrl ({ checkout }) {
    return checkout && checkout.webUrl
  },
  staticText: ({ locales, locale }) => (section, name) => {
    const loc = locale || 'en-gb'
    return locales[loc][section][name]
  },
  menus: ({ locales, locale }) => (name) => {
    const loc = locale || 'en-gb'
    return locales[loc].menus[name]
  },
  localeLink: ({ locale }) => (url, doc = false) => {
    if (doc) {
      return linkResolver(doc)
    }
    const loc = locale === 'it-it' ? '/it' : '/en'
    return loc + url
  },
  accountLink ({ locale }) {
    const base = locale === 'it-it' ? '' : '/en'

    return `https://shop.imersa.it${base}/account`
  },
  locale2 ({ locale }) {
    if (!locale || typeof locale !== 'string') return 'it'
    return locale.split('-')[0]
  }
}
