export default {
  'en-gb': {
    switch: {
      intro: 'Questo sito è anche disponibile in italiano.',
      cta: 'Passa alla versione italiana?',
      no: 'No grazie'
    },
    menus: {
      left: [
        { title: 'Shop', path: '/en/collections/all' },
        { title: 'The Science of Silk', path: '/en/science-of-silk' }
      ],
      right: [
        { title: 'Our Concept', path: '/en/concept' },
        { title: 'Journal', path: '/en/journal' },
        { title: 'Press', path: '/en/press' }
      ],
      sub: [
        { title: 'Shipping & Returns', path: '/en/pages/shipping-returns' },
        { title: 'Customer Service', path: '/en/pages/customer-service' },
        // { title: 'Press', path: '/pages/press' },
        { title: 'Contact Us', path: '/en/pages/contact' },
        { title: 'FAQs', path: '/en/pages/faqs' }
      ],
      footerTopLeft: [
        { title: 'Shipping & Returns', path: '/en/pages/shipping-returns' },
        { title: 'Customer Service', path: '/en/pages/customer-service' },
        { title: 'FAQs', path: '/en/pages/faqs' }
      ],
      footerBottomLeft: [
        { title: 'Privacy Policy', path: '/en/pages/privacy-policy' },
        { title: 'Terms of Service', path: '/en/pages/terms-of-service' },
        { title: 'Contact Us', path: '/en/pages/contact' }
      ]
    },
    general: {
      loading: 'Loading...',
      subscribe: 'Subscribe',
      subscribeFooter: 'Subscribe to our newsletter',
      subscribeThanks: 'Thanks for subscribing!',
      cancel: 'Stornare',
      close: 'Evincere',
      journal: 'Journal',
      moreJournal: 'More from the Journal',
      stayInTouch: 'Stay in touch on<br> <a href="https://www.instagram.com/imersa.it/" class="link" title="Imersa on Instagram" rel="noopener" target="_blank">Instagram</a>',
      instaError: 'Could not fetch posts from Instagram',
      privacy: 'Privacy policy',
      privacyLink: '/en/pages/privacy-policy',
      name: 'Name',
      firstName: 'Name',
      lastName: 'Last name',
      email: 'Email address',
      subject: 'Subject',
      msg: 'Your message*',
      send: 'Send',
      msgErr: 'Error sending message. Please try again later',
      msgOk: 'Message sent. We will get back to you soon',
      city: 'City',
      country: 'Country',
      search: 'Search',
      searchPlaceholder: 'Type to search',
      lang: 'Language',
      selectLang: 'Select language',
      explore: 'Explore the range',
      viewMore: 'View more',
      view: 'View',
      noResults: 'No results',
      searching: 'Searching...',
      cookies: 'We use cookies to optimise your visit to Imersa. By browsing imersa.it, you agree to our ',
      cookieLink: '/en/pages/terms-of-service',
      cookieLinkText: 'use of cookies',
      shopName: 'Shop - Imersa',
      account: 'Account'
    },
    signup: {
      permissions: 'Marketing permissions',
      permissions2: 'Please select all the ways you would like to hear from Imersa:',
      news: 'Email newsletter',
      promo: 'Email promotions',
      where: 'Country'
    },
    product: {
      add: 'Add to Bag',
      related: 'Related products',
      directions: 'Directions',
      ingredients: 'Ingredients',
      shop: 'Shop now',
      unavailable: 'Out of stock',
      rating: 'rating',
      ratings: 'ratings',
      reviewsTitle: 'Customer Reviews',
      write: 'Write a review',
      postReview: 'Submit Review',
      ratingLabel: 'Rating',
      titlePlace: 'Give your review a title',
      commentsPlace: 'Write your comments here',
      reviewSuccess: 'Thank you! Your review will appear here soon.',
      reviewError: 'Something went wrong. Please check all fields and try again',
      noReviews: 'No reviews yet',
      basedOn: 'Based on',
      review: 'review',
      reviews: 'reviews',
      cancel: 'Cancel',
      emailPrivate: 'Your email address will not be published.',
      notifyBtn: 'Email me when back in stock',
      notifyTitle: 'Notify via email',
      notifySendBtn: 'Notify me when available',
      notified: 'We will inform you when the article is available'
    },
    journal: {
      more: 'Read story',
      noPosts: 'No posts found'
    },
    pages: {
      contact: 'Contact Us',
      notFound: '404 Not Found',
      learn: 'Learn about Imersa'
    },
    cart: {
      cart: 'My bag',
      empty: 'No items in your bag.',
      continueShopping: 'Continue shopping',
      price: 'Price',
      qty: 'Quantity',
      subtotal: 'Subtotal',
      checkout: 'Check out',
      shipping: 'Shipping calculated at checkout',
      paymentOptions: 'More payment options'
    },
    samples: {
      nameLabel: 'Name*',
      name: 'First name*',
      lastname: 'Last name*',
      email: 'Email address*',
      address: 'Address*',
      number: 'House number*',
      city: 'City*',
      zip: 'ZIP/Postal code*',
      state: 'State*',
      note: 'Further info*'
    }
  },
  'it-it': {
    switch: {
      intro: 'This site is also available in English.',
      cta: 'Browse the English version?',
      no: 'No thanks'
    },
    menus: {
      left: [
        { title: 'Prodotti', path: '/it/collections/all' },
        { title: 'La Scienza della seta', path: '/it/scienza-della-seta' }
      ],
      right: [
        { title: 'La Nostra Storia', path: '/it/storia' },
        { title: 'Journal', path: '/it/journal' },
        { title: 'Press', path: '/it/press' }
      ],
      sub: [
        { title: 'Spedizioni & Resi', path: '/it/pages/spedizioni-e-resi' },
        { title: 'Servizio Clienti', path: '/it/pages/servizio-clienti' },
        // { title: 'PR & Media', path: '/pages/press' },
        { title: 'Contattaci', path: '/it/pages/contattaci' },
        { title: 'FAQs', path: '/it/pages/faqs' }
      ],
      footerTopLeft: [
        { title: 'Spedizione e Resi', path: '/it/pages/spedizioni-e-resi' },
        { title: 'Assistenza clienti', path: '/it/pages/servizio-clienti' },
        { title: 'FAQs', path: '/it/pages/faqs' }
      ],
      footerBottomLeft: [
        { title: 'Informativa Privacy', path: '/it/pages/privacy-policy' },
        { title: 'Condizioni di Utilizzo', path: '/it/pages/termini-e-condizioni' },
        { title: 'Contattaci', path: '/it/pages/contattaci' }
      ]
    },
    general: {
      loading: 'Caricamento…',
      subscribe: 'Iscriviti',
      subscribeFooter: 'Iscriviti alla nostra newsletter',
      subscribeThanks: 'Grazie per esserti iscritto!',
      cancel: 'Cancel',
      close: 'Close',
      journal: 'Journal',
      moreJournal: 'Altro dal diario',
      stayInTouch: 'Seguici su<br> <a href="https://www.instagram.com/imersa.it/" class="link" title="Instagram" rel="noopener" target="_blank">Instagram</a>',
      instaError: 'Non è possibile scaricare post da Instagram',
      privacy: 'Informativa Privacy',
      privacyLink: '/it/pages/privacy-policy',
      name: 'Nome',
      firstName: 'Nome',
      lastName: 'Cognome',
      email: 'Indirizzo e-mail',
      subject: 'Soggetto',
      msg: 'Il tuo messaggio*',
      send: 'Inviare',
      msgErr: 'Errore durante l\'invio del messaggio. Per favore riprova più tardi',
      msgOk: 'Messaggio inviato. Ti risponderemo al più presto',
      city: 'Città',
      country: 'Paese',
      search: 'Cerca',
      searchPlaceholder: 'Digita per cercare',
      lang: 'Lingua',
      selectLang: 'Seleziona la lingua',
      explore: 'Scopri la linea',
      viewMore: 'Scopri di più',
      view: 'Visualizza',
      noResults: 'Nessun risultato',
      searching: 'ricerca...',
      cookies: 'Utilizziamo i cookie per migliorare la navigazione su Imersa. Proseguendo nella navigazione si accetta ',
      cookieLink: '/it/pages/termini-e-condizioni',
      cookieLinkText: 'l’uso dei cookie',
      shopName: 'Prodotti - Imersa',
      account: 'Account'
    },
    signup: {
      permissions: 'Permessi di marketing',
      permissions2: 'Seleziona tutti i modi in cui vorresti ricevere notizie da Imersa:',
      news: 'Newsletter via e-mail',
      promo: 'Promozioni via e-mail',
      where: 'Paese'
    },
    product: {
      add: 'Aggiungi al Carrello',
      related: 'Prodotti simili',
      directions: 'Istruzioni',
      ingredients: 'Ingredienti',
      shop: 'Acquista',
      unavailable: 'Out of stock',
      rating: 'voto',
      ratings: 'voti',
      reviewsTitle: 'Recensioni dei clienti',
      write: 'Scrivi una recensione',
      postReview: 'Invia recensione',
      ratingLabel: 'Voto',
      titlePlace: 'Dai un titolo alla tua recensione',
      commentsPlace: 'Scrivi qui i tuoi commenti',
      reviewSuccess: 'Grazie! La tua recensione apparirà presto qui.',
      reviewError: 'Qualcosa è andato storto. Si prega di controllare tutti i campi e riprovare',
      noReviews: 'Nessuna recensione ancora',
      basedOn: 'Basato su',
      review: 'recensione',
      reviews: 'recensioni',
      cancel: 'Stornare',
      emailPrivate: 'Il tuo indirizzo e-mail non sarà pubblicato.',
      notifyBtn: 'Avvisami',
      notifyTitle: 'Avvisami via email',
      notifySendBtn: 'Avvisami quando disponibile',
      notified: 'Ti informeremo quando l\'articolo è disponibile'
    },
    journal: {
      more: 'Leggi la storia',
      noPosts: 'Nessun messaggio trovato'
    },
    pages: {
      contact: 'Contattaci',
      notFound: '404 Non Trovato',
      learn: 'Scopri Imersa'
    },
    cart: {
      cart: 'Carrello',
      empty: 'Il tuo carrello è vuoto.',
      continueShopping: 'Continua gli acquisti',
      price: 'Prezzo',
      qty: 'Quantità',
      subtotal: 'Totale',
      checkout: 'Check out',
      shipping: 'Costi di spedizione calcolati al check out',
      paymentOptions: 'Altre opzioni di pagamento'
    },
    samples: {
      nameLabel: 'Nome*',
      name: 'Nome*',
      lastname: 'Cognome*',
      email: 'Indirizzo e-mail*',
      address: 'Via*',
      number: 'Civico*',
      city: 'Città*',
      zip: 'CAP*',
      state: 'Stato*',
      note: 'Altre info sul recapito*'
    }
  }
}
