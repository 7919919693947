const sizes = ['large', 'tablet', 'mobile']

export default {
  name: 'AppImg',
  props: {
    src: {
      type: Object,
      required: true
    },
    bg: {
      type: Boolean,
      default: true
    },
    crop: {
      type: Boolean,
      default: true
    },
    height: Boolean
  },
  computed: {
    srcSet () {
      if (!sizes.every(i => this.src.hasOwnProperty(i)) || !this.crop) return this.src.url
      const sm = this.src.mobile.url
        ? `${this.src.mobile.url.replace('compress', 'none')} ${this.src.mobile.dimensions.width}w,` : ''
      const md = this.src.tablet.url
        ? `${this.src.tablet.url.replace('compress', 'none')} ${this.src.tablet.dimensions.width}w,` : ''
      const lg = this.src.large.url
        ? `${this.src.large.url.replace('compress', 'none')} ${this.src.large.dimensions.width}w` : ''
      return `${sm} ${md} ${lg}`
    }
  },
  render (h) {
    const set = this.bg ? 'data-bgset' : 'data-srcset'
    const opts = {
      'class': 'lazyload',
      attrs: {
        [set]: this.srcSet,
        'data-sizes': 'auto',
        alt: this.src.alt
        // width: this.src.dimensions.width,
        // height: this.src.dimensions.height
      }
    }

    return this.bg ? h('div', opts, this.$slots.default) : h('img', opts)
  }
}
