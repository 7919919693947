<template>
  <div class="pv6">
    <h2 class="f1 serif tracked ttu tc normal ma0">Loading preview...</h2>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    name: 'Preview',
    beforeCreate () {
      const previewToken = this.$route.query.token
      this.$prismic.client.previewSession(previewToken, this.$prismic.linkResolver, '/')
        .then((url) => {
          Cookies.set(this.$prismic.previewCookie, previewToken, { expires: 1 / 48 })
          window.location.replace(url)
        }).catch((err) => {
          console.log(err)
          window.location.replace('/en')
        })
    }
  }
</script>
