<template>
  <svg :class="{ jump }" :height="height" :style="style" class="v-mid icon inline-block" xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 54 54">
    <template v-if="showNum">
      <circle cx="22" cy="22" r="22" stroke-width="0" fill="rgb(207, 65, 63)"/>
      <text x="22" y="32" text-anchor="middle" fill="white" style="font-size:30px;">{{ count }}</text>
    </template>

    <template v-else>
      <g>
        <path class="a" d="M36.5658 18.4631V14.8459C36.5116 12.3465 35.4807 9.96782 33.6939 8.21935C31.9072 6.47088 29.5067 5.49176 27.0067 5.49176C24.5068 5.49176 22.1063 6.47088 20.3195 8.21935C18.5328 9.96782 17.5019 12.3465 17.4477 14.8459V18.4631H4L9.99795 48.4798H44.0021L50 18.4631H36.5658ZM20.1426 14.8459C20.1985 13.0629 20.946 11.3717 22.227 10.1302C23.5079 8.88874 25.2218 8.19452 27.0056 8.19452C28.7895 8.19452 30.5033 8.88874 31.7842 10.1302C33.0652 11.3717 33.8127 13.0629 33.8686 14.8459V18.4631H20.1426V14.8459ZM41.7896 45.8499H12.2217L7.26937 21.0929H17.4455V28.316H20.1381V21.0929H33.8529V28.316H36.5456V21.0929H46.7239L41.7896 45.8499Z" fill="currentColor"/>
      </g>
    </template>
    <!-- <g v-else transform="translate(0.5)">
      <path class="a" fill="currentColor" d="M14.513,5.768V4.156a4.261,4.261,0,0,0-8.52,0V5.768H0L2.673,19.145H17.827L20.5,5.768ZM7.194,4.156a3.06,3.06,0,0,1,6.117,0V5.768H7.194Zm9.647,13.817H3.664L1.457,6.94H5.992v3.219h1.2V6.94h6.112v3.219h1.2V6.94h4.536Zm0,0" transform="translate(-0.5)"/>
    </g> -->
  </svg>
</template>

<script>
  export default {
    name: 'CartIcon',
    props: {
      count: Number
    },
    data () {
      return {
        jump: false
      }
    },
    computed: {
      showNum () {
        return this.count && this.count > 0
      },
      height () {
        return this.showNum ? 26 : 22
      },
      style () {
        return {
          paddingTop: this.showNum ? '4px' : ''
        }
      }
    },
    watch: {
      count () {
        this.jump = true
        setTimeout(() => {
          this.jump = false
        }, 200)
      }
    }
  }
</script>

<style>
  .icon {
    transition: transform .2s ease-in-out;
  }
  .jump {
    transform: translateY(-3px) scale(1.2);
  }
</style>
