<template>
  <div class="pv6 ph3">
    <h2 class="f1 serif tracked ttu tc normal ma0">{{ staticText('pages', 'notFound')}}</h2>
    <div class="mt4">
      <p class="tc">
        <router-link :to="localeLink('/collections/all')" class="mh3 dib mb3 link">{{ staticText('general', 'explore') }}</router-link>
        <span class="dn dib-l">/</span>
        <router-link :to="localeLink('/concept')" class="mh3 dib mb3 link">{{ staticText('pages', 'learn') }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'NotFound',
    metaInfo: {
      title: '404 Not Found'
    },
    computed: {
      ...mapGetters(['staticText', 'localeLink'])
    }
  }
</script>
