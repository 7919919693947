import fecha from 'fecha'

export default function (doc) {
  if (doc.url && doc.url !== '') {
    return doc.url
  }
  const noRoutePrefix = ['journal', 'shop', 'subscribe']
  const lang = doc.lang === 'it-it' ? 'it' : 'en'
  switch (doc.type) {
  case 'pages':
    if (doc.uid === 'shop') return `/${lang}/collections/all`
    return noRoutePrefix.includes(doc.uid) ? `/${lang}/${doc.uid}` : `/${lang}/pages/${doc.uid}`
  case 'product':
    return `/${lang}/products/${doc.uid}`
  case 'campaign':
    return `/${lang}/c/${doc.uid}`
  case 'blog_post':
    if (!doc.data) {
      return `/${lang}/journal/${doc.uid}`
    }
    const pd = (doc.data && doc.data.publish_date) ||
      fecha.format(new Date(doc.first_publication_date || null), 'YYYY-MM-DD')
    const [year, month, day] = pd.split('-')
    return `/${lang}/journal/${year}/${month}/${day}/${doc.uid}`
  case 'press':
    return `/${lang}/press`
  case 'shop':
    return `/${lang}/collections/${doc.uid}`
  default:
    return `/${lang}${doc.uid ? '/' + doc.uid : ''}`
  }
}
