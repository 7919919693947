import coreJsonp from 'jsonp'
import linkResolver from '@/link-resolver'

export function inSSR () {
  return window && window.navigator.userAgent === 'ReactSnap'
}

export function mlToOz (ml = 0) {
  ml = parseInt(ml)
  if (isNaN(ml)) return 0

  let fl = ml * 0.033814
  return fl.toFixed(1)
}

export function jsonp (url = '', params = {}, opts = {}, fn) {
  let query = url.indexOf('?') === -1 ? '?' : '&'
  query += Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&')

  return coreJsonp(`${url}${query}`, opts, fn)
}

const currencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR'
})

export function formatMoney (price = '0') {
  if (typeof price !== 'string' && typeof price !== 'number') return 'NaN'
  return currencyFormat.format(parseFloat(price))
}

export function openCheckout (url, locale = 'en') {
  if (!url) return
  const u = new URL(url)
  u.searchParams.set('locale', locale)
  window.location.href = u.href
}

export function parseAlternate (langs = []) {
  return langs.map((l) => {
    return {
      rel: 'alternate',
      href: linkResolver(l),
      hreflang: l.lang.split('-')[0]
    }
  })
}

export function trailingSlash (url = '') {
  if (url.endsWith('/')) return url
  return url + '/'
}

export function currentUrl () {
  const u = (process.env.VUE_APP_PROD_URL || '') + window.location.pathname
  return trailingSlash(u)
}

export function encodeQuery (data) {
  return Object.keys(data)
    .map((key) => {
      if (!data[key]) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    }).join('&')
}

export function getLocal (key = '', fallback = false) {
  if (!window.localStorage) return fallback
  return window.localStorage.getItem(key) || fallback
}
