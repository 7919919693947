<template>
  <div :class="{ 'transform -translate-y-full': hiding, 'shadow-sm': !atTop && !hiding }" class="z-20 sticky top-0 transition-all duration-300">
    <header :class="classes" class="app-header lg:px-4 xl:px-16" role="banner">
      <div class="flex items-center px-4 lg:px-2">
        <div class="ttu px-4 lg:hidden flex-auto">
          <button @click="smNavVisible = !smNavVisible" class="bg-transparent outline-0 cursor-pointer" aria-label="Toggle menu">
            <hamburger/>
          </button>
        </div>

        <div class="lg:mr-8">
          <router-link :to="localeLink('')" active-class="" class="link">
            <h1 class="py-4 mv1">
              <logo title="Imersa" alt="Imersa" class="w-32 lg:w-36"/>
            </h1>
          </router-link>
        </div>

        <nav class="flex-auto">
          <ul class="list flex items-center ma0 pa0 nowrap">
            <li v-for="link in menus('left')" :key="link.title" class="ttu f7 hidden lg:inline-block tracked">
              <router-link :to="link.path" v-text="link.title" class="link ph3 pv2"/>
            </li>

            <li v-for="link in menus('right')" :key="link.title" class="ttu f7 dn dib-l tracked">
              <router-link :to="link.path" v-text="link.title" class="link ph3 pv2" />
            </li>
          </ul>
        </nav>

        <nav>
          <ul class="list flex items-center m-0 p-0 nowrap">
            <div class="flex-auto"></div>

            <li class="block lg:hidden ttu f14 flex-auto tr ph3">
              <button @click="cartVisible = true" class="bg-transparent outline-0 cursor-pointer" aria-label="Toggle shopping cart">
                <span class="sr-only">{{ staticText('cart', 'cart') }}</span>
                <cart-icon :count="cartCount"/>
              </button>
            </li>
            <li class="hidden lg:block f14 flex-auto tr ph3">
              <!-- <router-link :to="localeLink('/cart')" :title="staticText('cart', 'cart')" class="link" active-class=""> -->
              <button @click="cartVisible = true" class="bg-transparent outline-0 cursor-pointer" aria-label="Toggle shopping cart">
                <cart-icon :count="cartCount" aria-hidden="true"/>
                <span class="sr-only">{{ staticText('cart', 'cart') }}</span>
              </button>
              <!-- </router-link> -->
            </li>
            <li class="ttu f14 flex-auto hidden lg:inline-block">
              <a :href="accountLink">
                <account-icon aria-hidden="true"/>
                <span class="sr-only">{{ staticText('general', 'account') }}</span>
              </a>
            </li>
            <li class="ttu f14 flex-auto pl-4 hidden lg:inline-block">
              <button @click="searchVisible = !searchVisible" class="bg-transparent outline-0 cursor-pointer">
                <search-icon aria-hidden="true"/>
                <span class="sr-only">{{ staticText('general', 'search') }}</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <!-- mobile nav -->
      <transition name="fade">
        <div v-if="smNavVisible" class="absolute w-full bg-white sm-nav px-4 py-8 bt b--light-silver z-20">
          <div class="px-2">
            <ul class="list m-0 pt-0 pb-8 px-2 tl tracked f15 ttu">
              <li v-for="link in menus('left')" :key="link.title">
                <router-link :to="link.path" v-text="link.title" @click.native="maybeClose($event)" active-class="" class="link py-2 px-1 inline-block nowrap"/>
              </li>
              <li v-for="link in menus('right')" :key="link.title">
                <router-link :to="link.path" v-text="link.title" @click.native="maybeClose($event)" active-class="" class="link py-2 px-1 inline-block nowrap"/>
              </li>
            </ul>
            <div class="my-2 px-2 p-0 bt b--light-silver">
              <ul class="list m-0 px-0 pt-3 f15">
                <li>
                  <a :href="accountLink" class="link py-2 px-1 inline-block nowrap ttu">{{ staticText('general', 'account') }}</a>
                </li>
              </ul>
            </div>
            <div class="my-2 px-2 p-0 bt b--light-silver">
              <ul class="list m-0 px-0 pt-8 f15">
                <li v-for="link in menus('sub')" :key="link.title">
                  <router-link :to="link.path" v-text="link.title" @click.native="maybeClose($event)" active-class="" class="link py-2 px-1 inline-block nowrap"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </transition>
    </header>

    <!-- overlays and sliders -->
    <transition name="fade">
      <div v-if="overlay" @click="closeSlides" class="nav-bg z-10 fixed inset-0 min-h-screen bg-transparent"/>
    </transition>
    <transition name="slide-in-left">
      <cart v-if="cartVisible" @close="cartVisible = false"/>
    </transition>
    <transition name="fade">
      <div v-if="httpRequestsActive" :class="classes" class="request-animate fixed top-0 right-0 left-0 z-5"/>
    </transition>
    <transition name="fade">
      <search-box v-if="searchVisible" @close="closeSlides"/>
    </transition>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import Logo from '@/components/icons/Logo'
  import Hamburger from '@/components/icons/Hamburger'
  import SearchIcon from '@/components/icons/SearchIcon'
  import AccountIcon from '@/components/icons/AccountIcon'
  import CartIcon from '@/components/icons/CartIcon'
  import Cart from '@/components/Cart'
  import SearchBox from '@/components/SearchBox'
  import debounce from 'debounce'

  export default {
    name: 'AppHeader',
    data () {
      return {
        // httpRequestsActive: true,
        scrollEl: document.scrollingElement,
        // cartVisible: false,
        smNavVisible: false,
        searchVisible: false,

        atTop: true,
        scrollPos: 0,
        tempPos: 0,
        adding: false,
        hiding: false
      }
    },
    computed: {
      fontColor () {
        let c = this.$route.meta.color || 'black'
        return c !== 'white' || !this.smNavVisible ? c : 'black'
      },
      background () {
        return this.$route.meta.background || 'bg-white'
      },
      bgColor () {
        return this.smNavVisible ? 'bg-white' : this.background
      },
      hide () {
        return this.$route.meta.hideHeader
      },
      classes () {
        return [
          this.fontColor,
          this.bgColor,
          // this.smNavVisible ? 'z-10' : 'z-10',
          this.hide ? 'hide' : ''
        ]
      },
      overlay () {
        return this.cartVisible || this.smNavVisible || this.searchVisible
      },

      cartVisible: {
        get () {
          return this.$store.state.cartVisible && this.$route.name !== 'cart'
        },
        set (newVal) {
          this.$store.commit('CART_VISIBLE', newVal)
        }
      },

      ...mapState(['checkoutLoaded', 'httpRequestsActive']),
      ...mapGetters(['staticText', 'menus', 'localeLink', 'cartCount', 'accountLink'])
    },
    methods: {
      closeSlides () {
        this.cartVisible = false
        this.smNavVisible = false
        this.searchVisible = false
      },
      maybeClose ({ target }) {
        if (target.href === window.location.href) {
          this.closeSlides()
        }
      },
      onScroll () {
        if (this.adding) return

        const prevPos = this.scrollPos
        const pos = window.pageYOffset

        const dir = prevPos >= pos ? -1 : 1

        this.scrollPos = pos
        this.atTop = window.pageYOffset < 80

        if (this.atTop) {
          this.hiding = false
          return
        }

        if (dir === 1 && pos - this.tempPos > 20) {
          this.hiding = true
        } else if (this.tempPos - pos > 20) {
          this.hiding = false
          this.tempPos = pos
        } else {
          this.tempPos = pos
        }
      }
    },
    watch: {
      overlay (newVal) {
        if (newVal) {
          this.scrollEl.classList.add('overflow-hidden')
        } else {
          this.scrollEl.classList.remove('overflow-hidden')
        }
      },
      $route () {
        this.closeSlides()
      },
      async cartCount (newVal) {
        if (!this.checkoutLoaded) return

        if (newVal) {
          this.adding = true
          if (this.hiding) {
            this.hiding = false
            await sleep(300)
          }
          this.cartVisible = true
          await sleep(600)
          this.adding = false
        }
      }
    },
    mounted () {
      window.addEventListener('scroll', debounce(this.onScroll, 2))
    },
    components: { Logo, Hamburger, SearchIcon, AccountIcon, CartIcon, Cart, SearchBox }
  }

  function sleep (ms = 1000) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
</script>
