import store from '@/store'
import Vue from 'vue'

let client
async function getClient () {
  if (client) return
  const { default: shopify } = await import(/* webpackChunkName: 'shopify' */ '@/shopify-client')
  client = shopify
}

const vue = new Vue()

export default {
  noVersions ({ state, commit }, type, doc = {}) {
    if (!type) return commit('SET_ALTERNATE_VERSIONS')
    if (doc.alternate_languages && doc.alternate_languages.length) {
      return commit('SET_ALTERNATE_VERSIONS', doc)
    }

    const alt = state.languages.filter(l => l.locale !== state.locale)
      .map((l) => {
        const path = window.location.pathname.split('/').reverse()[0]
        return {
          type,
          lang: l.locale,
          uid: type !== 'home' && path
        }
      })
    commit('SET_ALTERNATE_VERSIONS', { alternate_languages: alt })
  },
  getGlobal ({ state, commit }) {
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('document.tags', ['global']),
      { lang: state.locale }
    ).then(({ results }) => {
      results.forEach((res) => {
        commit('GET_SINGLE', { name: res.type, data: res })
      })
    })
  },
  getSingle ({ state, commit }, name) {
    if (state.singles[name]) return Promise.resolve()
    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('document.type', name),
      { lang: state.locale }
    ).then(({ results }) => {
      commit('GET_SINGLE', { name, data: results[0] })
    }).finally(endRequest)
  },
  getPage ({ state, commit }, slug) {
    if (state.pages[slug]) return Promise.resolve()
    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('my.pages.uid', slug),
      { lang: state.locale }
    ).then(({ results }) => {
      if (!results[0]) return
      commit('GET_PAGE', { slug, data: results[0] })
    }).finally(endRequest)
  },
  getCampaign ({ state, commit }, slug) {
    if (state.campaigns[slug]) return Promise.resolve()
    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('my.campaign.uid', slug),
      { lang: state.locale }
    ).then(({ results }) => {
      if (!results[0]) return
      commit('GET_CAMPAIGN', { slug, data: results[0] })
    }).finally(endRequest)
  },

  getProducts ({ state, commit, dispatch }) {
    if (state.shop.length === state.prodCount) return

    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('document.type', 'product'),
      { orderings: '[my.product.number]', lang: state.locale }
    ).then((data) => {
      commit('SET_PRODUCTS', data)
      data.results.forEach((res) => {
        dispatch('getReviews', res.data)
      })
    }).finally(endRequest)
  },
  getProduct ({ state, commit, dispatch }, uid) {
    let found = state.shop.findIndex(p => p.uid === uid)
    if (found >= 0) return Promise.resolve()
    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('my.product.uid', uid),
      { lang: state.locale }
    ).then(({ results }) => {
      commit('SET_PRODUCT', results)
      results.forEach((res) => {
        dispatch('getReviews', res.data)
      })
    }).finally(endRequest)
  },

  getReviews (_, res) {
    if (!res.product || !res.product.id) return
    const uid = res.product.id
    const base = process.env.VUE_APP_REVIEWS
    return fetch(`${base}/${uid}`).then(async (rev) => {
      const data = await rev.json()
      // $set for proper reactivity
      Vue.set(res, 'reviews',  data)
    })
  },

  search ({ state }, searchVal) {
    startRequest()
    return vue.$prismic.client.query([
      vue.$prismic.Predicates.at('document.type', 'product'),
      vue.$prismic.Predicates.fulltext('document', searchVal.toString())
    ], { lang: state.locale }).finally(endRequest)
  },

  // shopify-buy
  async getCheckout ({ state, commit }) {
    if (state.checkoutId) {
      await getClient()
      return client.checkout.fetch(state.checkoutId).then((checkout) => {
        commit('SET_CHECKOUT', checkout)
      })
    }
  },
  async addToCart ({ state, commit }, lineItems) {
    await getClient()
    startRequest()
    commit('USER_ADD_TO_CART')
    if (!state.checkoutId) {
      const { attrs } = await client.checkout.create()
      commit('CHECKOUT_LOADED')
      commit('SET_CHECKOUT_ID', attrs)
    }
    return client.checkout.addLineItems(state.checkoutId, lineItems).then((checkout) => {
      commit('CHECKOUT_LOADED')
      commit('SET_CHECKOUT', checkout)
    }).catch(() => {
      commit('HTTP_ERROR', 'Could not add to cart')
    }).finally(endRequest)
  },
  async updateCart ({ state, commit }, lineItems) {
    await getClient()
    startRequest()
    return client.checkout.updateLineItems(state.checkoutId, lineItems).then((checkout) => {
      commit('CHECKOUT_LOADED')
      commit('SET_CHECKOUT', checkout)
    }).catch(() => {
      commit('HTTP_ERROR', 'Could not update cart')
    }).finally(endRequest)
  },

  getJournal ({ state, commit }, page = 1) {
    const pageSize = 7
    if (state.journal.length && !state.nextPage) return
    if (pageSize * page <= state.journal.length) return

    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('document.type', 'blog_post'),
      // { orderings: '[my.blog_post.publish_date desc]', pageSize, page } // no italian content yet..
      { orderings: '[my.blog_post.publish_date desc]', lang: state.locale, pageSize, page }
      // eslint-disable-next-line
    ).then(({ results, next_page }) => {
      commit('SET_NEXTPAGE', next_page)
      commit('SET_JOURNAL', results)
    }).finally(endRequest)
  },
  getJournalPost ({ state, commit }, uid) {
    let found = state.journal.findIndex(p => p.uid === uid)
    if (found >= 0) return Promise.resolve()
    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('my.blog_post.uid', uid),
      { lang: state.locale }
    ).then(({ results }) => {
      commit('SET_JOURNAL_POST', results)
    }).finally(endRequest)
  },

  getPress ({ commit }) {
    startRequest()
    return vue.$prismic.client.query(
      vue.$prismic.Predicates.at('document.type', 'press'),
      { orderings: '[my.press.date desc]' }
    ).then(({ results }) => {
      commit('SET_PRESS_ITEMS', results)
    }).finally(endRequest)
  },

  async getInstaData ({ state, commit }) {
    if (state.instaData && state.instaData.length) return

    const path = process.env.VUE_APP_INSTA_DATA + process.env.VUE_APP_INSTA_PATH

    return fetch(path).then((res) => res.json()).then((json) => {
      commit('INSTA_DATA', json)
      if (!json || !json.length) {
        commit('INSTA_ERR')
      }
    }).catch((err) => {
      console.log(err)
      commit('INSTA_ERR')
    })
  },

  async prefillCountry () {
    const path = process.env.VUE_APP_FUNCS_PATH + '/geo'
    return fetch(path).then(res => res.json()).catch(() => {})
  }
}

function startRequest () {
  store.commit('START_REQUEST')
  return Promise.resolve()
}

async function endRequest () {
  await timeout(200)
  store.commit('END_REQUEST')
  return Promise.resolve()
}

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
