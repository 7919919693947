<template>
  <div>
    <footer :class="hide" role="contentinfo" class="blue bt pa2 pa4-l cf">
      <div class="flex-l justify-between items-center relative ph2-l">
        <div class="order-1-l tc right-0-m top-0-m mv3 mv1-l">
          <p class="m-0 py-3 lg:pb-2">
            <logo title="Imersa" class="logo"/>
          </p>
        </div>

        <div class="nav-width-l tl order-0-l pl2-l pt2 pb3 pt0-l pb0-l flex justify-around flex-wrap" role="navigation">
          <ul class="list-reset ma0 pa0 w-50 w-100-l">
            <li v-for="link in menus('footerTopLeft')" :key="link.title" class="db tc tl-l dib-l pv1 f14">
              <router-link :to="link.path" class="link dib ph2">{{ link.title }}</router-link>
            </li>
          </ul>
          <ul class="list-reset ma0 pa0 w-50 w-100-l">
            <li v-for="link in menus('footerBottomLeft')" :key="link.title" class="db tc tl-l dib-l pv1 f14">
              <router-link :to="link.path" class="link dib ph2">{{ link.title }}</router-link>
            </li>
          </ul>
        </div>

        <div class="nav-width-l tc tr-l order-2-l pr2-l pt3 pt0-l">
          <ul class="list-reset ma0 pa0">
            <li class="dib ph1 ph0-l pv1 f14">
              <a href="https://www.instagram.com/imersa.it/" rel="noopener" target="_blank" class="link dib ph1 ph2-l">
                <span aria-hidden="true" class=""><img :data-src="require('../assets/instagram.svg')" class="lazyload" alt="Instagram" width="20" height="20" /></span>
                <span class="sr-only">Instagram</span>
              </a>
            </li>
            <li class="dib ph1 ph0-l pv1 f14">
              <a href="https://www.facebook.com/imersa.it" rel="noopener" target="_blank" class="link dib ph1 ph2-l">
                <span aria-hidden="true" class=""><img :data-src="require('../assets/facebook.svg')" class="lazyload" alt="Facebook" width="20" height="20" /></span>
                <span class="sr-only">Facebook</span>
              </a>
            </li>
            <li class="hidden">
              <a href="https://www.pinterest.com/imersa4838/" rel="noopener" target="_blank" class="link dib ph1 ph2-l">
                <span aria-hidden="true" class=""><img :data-src="require('../assets/pinterest.svg')" class="lazyload" alt="Pinterest" width="20" height="20" /></span>
                <span class="sr-only">Pinterest</span>
              </a>
            </li>
            <li class="dib ph1 ph0-l pv1 f14">
              <a href="https://www.tiktok.com/@imersa.it" rel="noopener" target="_blank" class="link dib ph1 ph2-l">
                <span aria-hidden="true" class=""><img :data-src="require('../assets/tiktok.svg')" class="lazyload" alt="TikTok" width="20" height="20" /></span>
                <span class="sr-only">TikTok</span>
              </a>
            </li>
            <li class="dib ph1 ph0-l pv1 f14">
              <a href="https://www.youtube.com/channel/UC1oZxm-SbMKkWAqKrTbV29g" rel="noopener" target="_blank" class="link dib ph1 ph2-l">
                <span aria-hidden="true"><img :data-src="require('../assets/youtube.svg')" class="lazyload" alt="Imersa on YouTube" width="20" height="20"/></span>
                <span class="sr-only">YouTube</span>
              </a>
            </li>
            <li class="dib ph1 ph0-l pv1 f14">
              <a href="https://open.spotify.com/user/31owoxorxvys7lv6focv7bwzhe2i?si=bb7297519b5140b0&nd=1" rel="noopener" target="_blank" class="link dib ph1 ph2-l">
                <span aria-hidden="true"><img :data-src="require('../assets/spotify.svg')" class="lazyload" alt="Imersa on Spotify" width="20" height="20"/></span>
                <span class="sr-only">Spoity</span>
              </a>
            </li>
          </ul>
          <ul class="list-reset ma0 pa0 dn db-l">
            <li class="dib pv1 f14">
              <a @click="showSignup" class="link dib ph1 ph2-l pointer">{{ staticText('general', 'subscribeFooter') }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tc mb3 mb0-l mt4 mt3-l pt3-l">
        <div class="tl w5 mw-100 center">
          <div class="f14 flex justify-center items-center nl2 nr2">
            <label for="locale" class="pointer bb bw1 b--transparent ph2">
              <img :data-src="require('../assets/globe.svg')" :alt="staticText('general', 'lang')" class="pb1 o-80 lazyload inline-block" width="11" height="11"/>
              <span class="pl1">{{ staticText('general', 'lang') }}</span>
            </label>
            <div class="ph2">
              <div class="select relative">
                <select v-model="locale" id="locale">
                  <option value="" disabled>{{ staticText('general', 'selectLang') }}</option>
                  <option v-for="l in locales" :key="l.title" :value="l.locale">{{ l.title }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 lg:mt-8">
          <payment-icon-list/>
        </div>

        <p class="f7 mt2 mt4-l tracked">&copy; {{ year }} IMERSA <br /> Leonardino S.r.l., Via Ghisalba, 13 20021 Bollate (MI) – Italia, P.iva IT08847710962</p>
      </div>
    </footer>

    <div>
      <switch-lang @change="changeLocale" role="dialog"/>
    </div>

    <transition name="fade">
      <signup v-if="signupVisible" @close="hideSignup" @complete="complete" :fields="newsletterFields" role="dialog"/>
    </transition>
  </div>
</template>

<script>
  import Logo from '@/components/icons/Logo'
  import Signup from '@/components/Signup'
  import SwitchLang from '@/components/SwitchLang'
  import PaymentIconList from '@/components/icons/PaymentIconList.vue'
  import { parseAlternate } from '@/util'
  import { mapGetters } from 'vuex'
  import Cookies from 'js-cookie'

  const pageName = 'homepage'
  const withAlternates = ['product', 'post', 'page', 'concept', 'science']

  const key = 'imersa.signupshown'

  export default {
    name: 'AppFooter',
    metaInfo () {
      return {
        link: [...this.altLinks]
      }
    },
    data () {
      return {
        seen: false
      }
    },
    computed: {
      journal () {
        return this.$route.path.includes('/journal')
      },
      locales () {
        return this.$store.state.languages
      },
      newsletterPage () {
        return this.$store.state.singles[pageName]
      },
      newsletterFields () {
        return this.newsletterPage && this.newsletterPage.data
      },
      color () {
        return this.$route.meta.color || 'black'
      },
      fontColor () {
        return this.color !== 'white' ? this.color : 'black'
      },
      year () {
        return new Date().getFullYear()
      },
      hasSubscribed () {
        return this.$store.state.hasSubscribed
      },
      signupVisible: {
        get () {
          return this.$store.state.signupVisible
        },
        set (newVal) {
          this.$store.commit('TOGGLE_SIGNUP', newVal)
        }
      },
      locale: {
        get () {
          return this.$store.state.locale
        },
        set (newVal) {
          this.$store.commit('CHANGE_LOCALE', newVal)
          this.changeLocale(newVal)
        }
      },
      routeWithAlternate () {
        const { name } = this.$route
        return withAlternates.includes(name)
      },
      alternateLanguages () {
        return this.$store.state.alternateLanguages
      },
      altLinks () {
        // if (this.journal) return []
        return parseAlternate(this.alternateLanguages)
      },
      hide () {
        return this.$route.meta.hideHeader ? 'dn' : ''
      },

      ...mapGetters(['staticText', 'menus'])
    },
    methods: {
      async showSignup () {
        if (!this.newsletterFields) {
          await this.$store.dispatch('getSingle', pageName)
        }
        this.signupVisible = true
      },
      hideSignup () {
        this.signupVisible = false
        this.popupSeen()
      },
      complete () {
        this.$store.commit('SUBSCRIBED')
        this.hideSignup()
      },
      changeLocale (val) {
        if (!val) return
        const c = this.locales.find(l => l.locale === val)
        const alt = this.altLinks.find(l => l.hreflang === c.code)
        if (alt && alt.href) {
          window.location.href = alt.href
        } else {
          alert('No alternate language version found')
          window.location.reload()
        }
      },
      popupSeen () {
        this.seen = true
        Cookies.set(key, true, { expires: 7 })
      }
    },
    mounted () {
      if (Cookies.get(key)) {
        this.seen = true
      } else {
        setTimeout(() => {
          this.signupVisible = true
        }, 6000)
      }
    },
    components: { Logo, Signup, SwitchLang, PaymentIconList }
  }
</script>
