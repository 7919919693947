<template>
  <div @click.self="closeMe" class="fixed z-40 top-0 right-0 left-0 min-h-screen flex justify-center items-start pa3 pa4-l bg-white-80 pt6-l">
    <div class="bg-white br2 shadow-4 pa4 mb6 mw7 w-100">
      <form @submit.prevent role="search">
        <input v-model="query" @input="onInput" :placeholder="staticText('general', 'searchPlaceholder')" ref="searchbox" class="input-reset ba pa3 w-100" type="search" />
      </form>
      <div v-if="results.length">
        <ul class="list pa0 ma0">
          <li v-for="(result, i) in results" :key="i" class="mt3">
            <router-link :to="itemLink(result)" class="link color-inherit f18 serif tracked ttu">
              <span v-if="result.data.number">N<sup>O</sup>{{ result.data.number }} </span>
              <span>{{ result.data.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div v-if="noResults" class="pt3">
        <p class="gray ttu serif measure">{{ staticText('general', 'noResults') }}</p>
      </div>
      <div v-if="busy" class="pt3">
        <p class="gray ttu serif measure">{{ staticText('general', 'searching') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import linkResolver from '@/link-resolver'
  import debounce from 'debounce'

  export default {
    name: 'SearchBox',
    data () {
      return {
        busy: false,
        query: '',
        results: []
      }
    },
    computed: {
      noResults () {
        return (this.query && this.query.length > 2) && !this.busy && !this.results.length
      },

      ...mapGetters(['staticText'])
    },
    methods: {
      onInput () {},

      submit () {
        if (!this.query || this.query.length < 3) {
          this.results = []
          return
        }
        this.busy = true
        this.search(this.query).then(({ results }) => {
          this.busy = false
          this.results = results
        })
      },
      closeMe () {
        this.$emit('close')
      },
      itemLink (item) {
        return linkResolver(item)
      },

      ...mapActions(['search'])
    },
    mounted () {
      this.onInput = debounce(this.submit, 280)
      this.$refs.searchbox.focus()
    }
  }
</script>
