<template>
  <div :class="classes">
    <template v-if="featured">
      <div class="flex flex-wrap">
        <div class="max-w-4xl center pt3 pt0-ns">
          <h2 v-text="data.title" class="f4 f2-ns f1-l serif tracked tc normal mt0 mb4 whitespace-pre-line"></h2>
        </div>
        <div class="w-100">
          <router-link :to="postLink">
            <app-img :src="landscapeImg" class="aspect-ratio--6x4 cover bg-center"/>
          </router-link>
          <div>
            <div class="tc measure-wide center f18 mv4">
              <prismic-rich-text :field="data.excerpt" />
            </div>
            <p class="f7 tc tracked ma0 ttu">
              <router-link :to="postLink" class="link">{{ staticText('journal', 'more') }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="landscape">
      <div>
        <router-link :to="postLink">
          <app-img :src="landscapeImg" class="aspect-ratio--7x5 cover bg-center" />
        </router-link>
        <h2 v-text="data.title" class="f18 serif tracked tc normal mt4 mb3 center"></h2>
        <p class="f7 tracked ttu tc ma0 pt2">
          <router-link :to="postLink" class="link">{{ staticText('journal', 'more') }}</router-link>
        </p>
      </div>
    </template>

    <template v-else-if="related">
      <div>
        <router-link :to="postLink">
          <app-img :src="thumbnail" class="dn db-ns aspect-ratio--5x7-ns cover bg-center" />
          <app-img :src="landscapeImg" class="dn-ns aspect-ratio--7x5 cover bg-center" />
        </router-link>
        <div class="w-100-ns center ws-normal">
          <h2 v-text="data.title" class="f18 serif tracked tc normal mt4 mb3"></h2>
          <p class="f7 tracked ttu tc ma0 pt1">
            <router-link :to="postLink" class="link">{{ staticText('journal', 'more') }}</router-link>
          </p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="mb4 mb0-ns">
        <router-link :to="postLink">
          <app-img :src="thumbnail" class="aspect-ratio--3x4 aspect-ratio--5x7-ns cover bg-center" />
        </router-link>
        <div class="w-100-ns center">
          <h2 v-text="data.title" class="f27 f18-ns serif tracked tc normal mt4 mb3"></h2>
          <div class="dn-ns tc measure-wide center f18 mv3 mv4-l">
            <prismic-rich-text :field="data.excerpt" />
          </div>
          <p class="f7 tracked ttu tc ma0 pt1">
            <router-link :to="postLink" class="link">{{ staticText('journal', 'more') }}</router-link>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'JournalGridItem',
    props: {
      fields: {
        type: Object,
        required: true
      },
      featured: Boolean,
      landscape: Boolean,
      related: Boolean
    },
    computed: {
      data () {
        return this.fields.data
      },
      classes () {
        return this.featured ? 'w-100' : this.landscape
          ? 'w-100 w-50-ns mb5 ph3 ph4-l' : 'w-100 w-third-ns dip mb4 mb5-ns ph3-ns ph4-l'
      },
      postLink () {
        return this.$prismic.linkResolver(this.fields)
      },
      thumbnail () {
        return this.data.post_thumbnail
      },
      landscapeImg () {
        return this.data.landscape_image
      },

      ...mapGetters(['staticText'])
    }
  }
</script>
