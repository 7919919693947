<template>
  <div>
    <transition @enter="showEl" @leave="hideEl">
      <div v-if="visible" ref="fader" class="bg-light-red red tc lh-copy f15 overflow-hidden z-10 keen-slider !grid grid-cols-none">
        <!-- <div class="pv3 ph5">
          <span v-text="fields.text" class=""></span>
          <button v-if="signupLink" @click="showSignup" class="link ml-4 px-1 underline pointer">{{ fields.cta }}</button>
          <prismic-link v-else-if="fields.cta" :field="fields.link" class="link ml2 ph1 underline pointer">{{ fields.cta }}</prismic-link>
          <div class="absolute top-0 right-0 ma3">
            <a @click="close" class="f5 lh-solid pointer">&times;</a>
          </div>
        </div> -->
        <div v-for="row in rows" class="pv3 ph5 relative keen-slider__slide w-full flex-none row-start-1 col-start-1">
          <prismic-rich-text :field="row.primary.text1" class="lined"/>
          <div class="absolute top-0 right-0 ma3">
            <a @click="close" class="f5 lh-solid pointer">&times;</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import anime from 'animejs'
  import Cookies from 'js-cookie'
  const key = 'it.imersa.announcement'
  import KeenSlider from 'keen-slider'

  export default {
    name: 'Announcement',
    data () {
      return {
        pageName: 'announcement',
        elHeight: 0,
        slider: null
      }
    },
    computed: {
      visible: {
        get () {
          return this.$store.state.announcement
        },
        set (newVal) {
          this.$store.commit('TOGGLE_ANNOUNCEMENT', newVal)
        }
      },
      page () {
        return this.$store.state.singles[this.pageName]
      },
      fields () {
        return this.page.data
      },
      rows () {
        return this.fields?.body || []
      },
      newsletterPage () {
        return this.$store.state.singles['homepage']
      },
      newsletterFields () {
        return this.newsletterPage && this.newsletterPage.data
      },
      signupVisible: {
        get () {
          return this.$store.state.signupVisible
        },
        set (newVal) {
          this.$store.commit('TOGGLE_SIGNUP', newVal)
        }
      },
      signupLink () {
        return this.fields.cta && this.fields.link && this.fields.link.uid === 'subscribe'
      }
    },
    methods: {
      ifNews (e) {
        console.log(e)
      },
      close () {
        this.visible = false
        Cookies.set(key, true, { expires: 1 })
      },
      showEl (el, done) {
        const complete = () => {
          el.style.height = 'auto'
          done()
        }
        let t = anime.timeline({ complete })
        const r = el.getBoundingClientRect()
        let h = r.height
        this.elHeight = h
        el.style.height = 0
        t.add({
          targets: el,
          height: h,
          opacity: 1,
          duration: 540,
          easing: 'easeInOutCubic'
        })
      },
      hideEl (el, done) {
        let t = anime.timeline({ complete: done })
        const h = el.getBoundingClientRect().height
        el.style.height = h + 'px'
        t.add({
          targets: el,
          height: 0,
          opacity: 0,
          duration: 540,
          easing: 'easeInOutCubic'
        })
      },
      async showSignup () {
        if (!this.newsletterFields) {
          await this.getSingle('homepage')
        }
        this.signupVisible = true
      },
      hideSignup () {
        this.signupVisible = false
      },

      ...mapActions(['getSingle'])
    },
    mounted () {
      let seen = Cookies.get(key)
      if (seen) return

      setTimeout(() => {
        this.getSingle(this.pageName).then(() => {
          if (
            this.fields &&
            this.fields.visibility === 'Visible' &&
            window.navigator.userAgent !== 'ReactSnap'
          ) {
            this.visible = true
          }
        })
      }, 2000)
    },
    watch: {
      visible (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.slider = new KeenSlider(this.$refs.fader, {
              loop: true,
              defaultAnimation: {
                duration: 1200
              },
              detailsChanged: (s) => {
                s.slides.forEach((element, idx) => {
                  const portion = s.track.details.slides[idx].portion
                  element.style.opacity = portion
                  if (portion >= 0.5) {
                    element.style.pointerEvents = 'auto'
                  } else {
                    element.style.pointerEvents = 'none'
                  }
                })
              },
              renderMode: 'custom'
            }, [
              (slider) => {
                let timeout
                let mouseOver = false
                function clearNextTimeout() {
                  clearTimeout(timeout)
                }
                function nextTimeout() {
                  clearTimeout(timeout)
                  if (mouseOver) return
                  timeout = setTimeout(() => {
                    slider.next()
                  }, 3000)
                }
                slider.on('created', () => {
                  slider.container.addEventListener('mouseover', () => {
                    mouseOver = true
                    clearNextTimeout()
                  })
                  slider.container.addEventListener('mouseout', () => {
                    mouseOver = false
                    nextTimeout()
                  })
                  nextTimeout()
                })
                slider.on('dragStarted', clearNextTimeout)
                slider.on('animationEnded', nextTimeout)
                slider.on('updated', nextTimeout)
              }
            ])
          })
        }
      }
    }
  }
</script>
