import linkResolver from '../link-resolver'
// import { RouterLink } from 'vue-router'

export default {
  name: 'AnchorSwitch',
  props: {
    url: [String, Object],
    tag: {
      type: String,
      default: 'a'
    }
  },
  render (h) {
    let url = this.url
    const classes = this.$attrs.class
    const target = url && url.target

    url = extractLink(url)
    if (!url) {
      return h('span', { class: classes }, this.$slots.default)
    }
    url = noTrailing(url)
    if (url.includes('://') || url.includes('mailto:') || url.includes('tel:')) {
      const o = { class: classes }
      const attrs = { href: url }
      if (target) {
        attrs.target = target
        attrs.rel = 'noopener'
      }
      o.attrs = attrs
      return h('a', o, this.$slots.default)
    }
    return h('router-link', {
      props: {
        to: this.cmsLink(url)
      },
      class: classes
    }, this.$slots.default)
  },
  methods: {
    cmsLink (doc) {
      if (doc && doc.link_type) return linkResolver(doc)

      let path = doc

      // nav link...
      if (doc && doc.resource) {
        path = `/${doc.resource}/${doc.handle}`
      }

      if (path === '') {
        path = '/'
      }
      return path
    }
  }
}

export function extractLink (val) {
  if (typeof val === 'string' || !val) return val
  let str = ''
  if (val.resource) {
    str += `/${val.resource}`
  }
  if (val.handle) {
    str += `/${val.handle}`
  }
  if (val.variant && typeof val.variant === 'string') {
    str += `?variant=${val.variant}`
  }
  if (str.length) return str

  // link to prismic content
  if (val.link && val.link.link_type) {
    val = val.link
  }
  switch (val.link_type) {
    case 'Document':
      return linkResolver(val)
    case 'Web':
      return val.url
    case 'Media':
      return val.url
    default:
      return false
  }
}

function noTrailing (str = '') {
  const x = str.lastIndexOf('/')

  return x === str.length - 1 ? str.substr(0, x) : str
}
