<template>
  <div @click.self="close" class="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-50 bg-white-30">
    <div class="bg-light-red red shadow-4 relative w-100 mw7 max-vh-100 mv-auto overflow-auto mh3-m vh-100 h-auto-ns">
      <div class="pa3 pa5-l tc">
        <form @submit.prevent="submit" v-if="!done">
          <p v-text="fields.newsletter_title" class="f30 serif tracked ttu mb4"></p>
          <prismic-rich-text :field="fields.newsletter_message" class="f17"/>
          <div class="flex items-end mt4">
            <input v-model="signup.firstName" ref="first" type="text" name="FNAME" :placeholder="staticText('general', 'firstName')" required class="input-reset w-100 bg-transparent ba red b--red pa3">
          </div>
          <div class="mt3">
            <input v-model="signup.email" ref="email" type="email" name="EMAIL" required :placeholder="staticText('general', 'email')" class="input-reset w-100 bg-transparent ba red b--red pa3">
          </div>
          <div class="mt3">
            <label for="region" class="db tl mb-1">{{ staticText('signup', 'where') }}</label>
            <div class="relative w-full sel">
              <select v-model="signup.country" type="select" id="region" class="input-reset w-100 bg-transparent ba red b--red pa3">
                <option v-for="c in countries" :key="c.key" :value="c.name">{{ c.name }}</option>
              </select>
            </div>
          </div>
          <div class="mt3 tl hide">
            <p class="b ma0">{{ staticText('signup', 'permissions') }}</p>
            <p class="ma0">{{ staticText('signup', 'permissions2') }}</p>
            <div class="mt2">
              <label class="flex items-center">
                <input v-model="signup.gdpr1" type="checkbox" name="gdpr[61689]" class="mr2">
                <span>{{ staticText('signup', 'news') }}</span>
              </label>
            </div>
            <div class="hide">
              <label class="flex items-center">
                <input v-model="signup.gdpr2" type="checkbox" name="gdpr[61697]" class="mr2">
                <span>{{ staticText('signup', 'promo') }}</span>
              </label>
            </div>
          </div>
          <div class="mt4">
            <p v-if="submitError" v-html="submitError"></p>
          </div>
          <div class="mt4 tc">
            <input type="submit" :value="staticText('general', 'subscribe')" class="btn pointer input-reset bg-transparent ba red pv3 w-50 b--red sans-serif tracked ttu f13 br0">
          </div>
          <div class="mt3">
            <p class="f7">
              <span @click.stop="close">
                <router-link :to="staticText('general', 'privacyLink')">{{ staticText('general', 'privacy') }}</router-link>
              </span>
            </p>
          </div>
        </form>
        <div v-else>
          <p class="f30 serif tracked ttu mb4">{{ staticText('general', 'subscribeThanks') }}</p>
          <div class="f17">
            <p v-text="submitMessage"></p>
          </div>
          <div class="mt4 tc">
            <button @click="close" class="btn pointer input-reset bg-transparent ba red pv3 w-50 b--red sans-serif tracked ttu f13 br0">OK</button>
          </div>
        </div>
      </div>
      <div class="absolute top-0 right-0 ma3 ph1">
        <a @click="close" class="f3 lh-solid pointer">&times;</a>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import { mapGetters } from 'vuex'
  import { jsonp } from '@/util'
  import countries from 'i18n-iso-countries'

  import it from 'i18n-iso-countries/langs/it.json'
  import en from 'i18n-iso-countries/langs/en.json'

  const mcUrl = process.env.VUE_APP_MC_URL
  const mcUser = process.env.VUE_APP_MC_USER

  const IT = process.env.VUE_APP_MC_LIST_ID
  const REST = process.env.VUE_APP_MC_INT_LIST_ID

  export default {
    name: 'Signup',
    props: {
      fields: Object
    },
    data () {
      return {
        countries: [],
        signup: {
          firstName: null,
          lastName: null,
          email: null,
          city: null,
          country: null,
          gdpr1: true,
          gdpr2: false
        },
        submitMessage: null,
        submitError: null,
        done: false
      }
    },
    computed: {
      mcListID () {
        const it = process.env.VUE_APP_MC_LIST_ID
        const int = process.env.VUE_APP_MC_INT_LIST_ID

        return this.$store.state.locale === 'it-it' ? it : int
      },
      country () {
        return this.signup.country
      },
      list () {
        return (this.country === 'Italia' || this.country === 'Italy') ? IT : REST
      },
      perms () {
        if (!this.list) return ''
        return this.list === IT ? 'gdpr[17869]' : 'gdpr[43585]'
      },
      locale () {
        return this.$store.state.locale
      },
      ...mapGetters(['staticText'])
    },
    methods: {
      close () {
        if (this.done) {
          this.finished()
        } else {
          this.$emit('close')
        }
      },
      finished () {
        this.$emit('complete')
      },
      submit () {
        this.submitError = false
        if (!this.signup.email) {
          this.submitError = 'Please include your email address'
          return
        }
        store.commit('START_REQUEST')

        const form = {
          u: mcUser,
          id: this.list, // this.mcListID || this.mcListID,
          EMAIL: this.signup.email,
          MMERGE5: this.signup.firstName,
          MMERGE4: this.signup.country
        }
        if (this.signup.gdpr1) {
          const key = this.perms
          form[key] = 'Y'
        }

        try {
          jsonp(mcUrl, form, { param: 'c' }, (err, data) => {
            if (err) {
              console.log(err)
              this.submitError = err.message
            } else {
              if (data.result === 'error') {
                this.submitError = data.msg
              } else {
                this.done = true
                this.submitMessage = data.msg
              }
            }
            store.commit('END_REQUEST')
          })
        } catch (err) {
          console.error(err)
          this.submitErr = 'Something went wrong'
        }
      }
    },
    async mounted () {
      this.$refs.first.focus()


      const lang = this.locale === 'it-it' ? 'it' : 'en'

      this.$store.dispatch('prefillCountry').then((data) => {
        if (data && 'country' in data) {
          this.signup.country = data.country[lang]
        } else {
          this.signup.country = lang === 'it' ? 'Italia' : 'Italy'
        }
      })

      if (lang === 'it') {
        countries.registerLocale(it)
      } else {
        countries.registerLocale(en)
      }

      const names = countries.getNames(lang)

      Object.entries(names).forEach((c) => {
        this.countries.push({
          key: c[0],
          name: c[1]
        })
      })
    },
    created () {
      document.scrollingElement.classList.add('overflow-hidden')
    },
    destroyed () {
      document.scrollingElement.classList.remove('overflow-hidden')
    }
  }
</script>
